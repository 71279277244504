export const mapEn = {
  panel_objects_hdr_library: 'Library',
  panel_objects_hdr_essence: 'Object',
  panel_objects_hdr_line: 'Line',
  panel_objects_hdr_plane: 'Plane',
  panel_objects_hdr_scene: 'Scene',
  panel_objects_hdr_text: 'Text',
  panel_objects_btn_object: 'Objects',
  panel_objects_btn_icons: 'Icons',
  panel_objects_edit_search: 'Search',
  panel_objects_btn_lock_panel: 'Lock panel',
  panel_objects_btn_unlock_panel: 'Unlock panel',
  panel_objects_btn_move_object: 'Move object',
  panel_objects_btn_make_line: 'Make line',
  panel_objects_btn_connect: 'Connect',
  panel_objects_btn_add_point_on_line: 'Add point on line',
  panel_objects_btn_make_plane: 'Make plane',
  panel_objects_btn_edit_plane: 'Edit plane',
  panel_objects_btn_make_text: 'Make text',
  panel_objects_btn_change_base_object: 'Change base object',
  panel_objects_btn_move_icon: 'Move icon',
  panel_objects_btn_ScreenShot: 'ScreenShot',
  properties_panel_visualization_edit_notification: 'Notification',
  properties_panel_properties_scene_lbl_color_header: 'Color',
  properties_panel_properties_scene_lbl_effects: 'effects',
  properties_panel_properties_scene_btn_lose: 'Lose',
  properties_panel_properties_scene_btn_progressbar_red: 'ProgressbarRed',
  properties_panel_properties_scene_btn_progressbar_yellow: 'ProgressbarYellow',
  main_menu_btn_new_config: 'New config',
  main_menu_btn_open_config: 'Open scene',
  main_menu_btn_append_from_file: 'Import from file',
  main_menu_btn_download_config: 'Save scene',
  main_menu_btn_add_new_component_to_library: 'Add new object to library',
  main_menu_btn_add_new_icon_library: 'Add new icon to library',
  main_menu_btn_add_new_gif_effect_to_library: 'Add new gif effect to library',
  add_gif_lbl_add_gif: 'Add gif effect',
  add_gif_hdr_add_gif: 'Select object for which you want to add gif effect',
  add_gif_edit_search_object: 'Search',
  add_gif_edit_name_effect: 'Name',
  add_gif_edit_name_effect_plh: 'Enter effect name',
  add_gif_chk_hide_component: 'Hide component',
  add_gif_btn_choose_gif: 'Open gif',
  add_gif_lbl_offset: 'Axis shift effect:',
  add_gif_spinneredit_offset_x: 'Offset X',
  add_gif_spinneredit_offset_y: 'Offset Y',
  add_gif_file_incorrect_format: 'Incorrect file format.\nValid formats: *.gif',
  add_gif_btn_save: 'Save',
  add_gif_btn_clear: 'Clear',
  add_image_hdr_add_image: 'Add image',
  add_image_edit_input_name_image: 'Name',
  add_image_edit_input_name_image_plh: 'Enter icon name',
  add_image_edit_input_name_image_empty: 'Mandatory field missed',
  add_image_file_incorrect_format: 'Incorrect file format.\nValid formats: *.svg or *.png, *.jpeg',
  add_object_hdr_add_add_object: 'Add object to library',
  add_object_edit_name_object: 'Name',
  add_object_edit_name_object_plh: 'Enter object name',
  add_object_edit_name_object_empty: 'Mandatory field missed',
  add_object_file_incorrect_format: 'Incorrect file format.\nValid formats: *.png, *.jpeg',
  add_object_btn_upload: 'Upload file',
  add_object_btn_reload: 'Reload file',
  add_object_btn_add: 'Add',
  add_object_btn_cancel: 'Cancel',
  add_object_btn_close: 'Close',
  add_object_lbl_same_size: 'All images must be the same size',
  add_object_lbl_no_file_loaded: 'No file loaded!',
  add_object_hdr_upload_error: 'Loading error',
  grid_edit_grid: 'Grid',
  context_menu_lst_block: 'Block',
  context_menu_lst_unblock: 'Unblock',
  context_menu_lst_delete: 'Delete',
  context_menu_lst_copy_triggers: 'Copy triggers',
  context_menu_lst_past_triggers: 'Paste triggers',
  properties_panel_object_toggler_panel_pinned: 'Panel pinned',
  properties_panel_object_toggler_panel_unpinned: 'Panel unpinned',
  properties_panel_object_btn_blocked: 'Unblock',
  properties_panel_object_btn_unblocked: 'Block',
  properties_panel_object_btn_set_trigges: 'Set triggers',
  properties_panel_object_btn_bring_to_front: 'Bring to front',
  properties_panel_object_btn_bring_to_back: 'Bring to back',
  properties_panel_object_lbl_default_options: 'Default options',
  properties_panel_object_edit_label: 'Label',
  properties_panel_object_lbl_state: 'State',
  properties_panel_object_btn_state_off: 'StateOff',
  properties_panel_object_btn_state_on: 'StateOn',
  properties_panel_object_btn_state_warning: 'StateWarning',
  properties_panel_object_btn_state_critical: 'StateCritical',
  properties_panel_object_lbl_effects: 'Effects',
  properties_panel_object_btn_attack_down: 'Attack Down',
  properties_panel_object_btn_progressbar_yellow: 'ProgressbarYellow',
  properties_panel_object_btn_progressbar_red: 'ProgressbarRed',
  properties_panel_object_btn_network_scanning: 'NetworkScanning',
  properties_panel_object_btn_vulnerabilities_scanning: 'VunerabilitiesScanning',
  properties_panel_object_btn_user_settings: 'UserSettings',
  properties_panel_object_btn_warning_network_scan: 'WarningNetworkScan',
  properties_panel_object_btn_addtitional_scan_blue: 'AdditionalScanBlue',
  properties_panel_object_btn_critical_succesful_attack: 'CriticalSuccesfulAttack',
  properties_panel_object_btn_addtitional_scan_green: 'AdditionalScanGreen',
  properties_panel_object_lbl_gif_effects: 'Gif effects',
  properties_panel_object_lbl_icons: 'Context icon',
  properties_panel_object_btn_delete_icon: 'Delete',
  properties_panel_object_lbl_change_center: 'Change center',
  properties_panel_object_btn_change_center: 'Change center',
  properties_panel_object_btn_delete_object: 'Delete',
  properties_panel_object_edit_rotate_angle: 'Rotate angle',
  properties_panel_object_edit_skew_angle: 'Skew angle',
  properties_panel_object_lbl_info_properties: 'Select only one object to display properties',
  properties_panel_object_lbl_color_properties: 'Color',
  properties_panel_object_lbl_font_size: 'Font size',
  properties_panel_object_lbl_line_properties: 'Line properties',
  properties_panel_object_edit_start: 'Start',
  properties_panel_object_edit_end: 'End',
  properties_panel_object_edit_start_x: 'Start x',
  properties_panel_object_edit_start_y: 'Start y',
  properties_panel_object_edit_end_x: 'End x',
  properties_panel_object_edit_end_y: 'End y',
  properties_panel_object_edit_line_width: 'Line width',
  properties_panel_object_btn_animate_direction: 'Animate direction',
  properties_panel_object_btn_animate_direction_reverse: 'Reverse direction',
  test_animation_hdr_test: 'Animation testing',
  test_animation_btn_default: 'Default',
  test_animation_btn_update_scene: 'Update scene',
  test_animation_btn_add_base_item: 'Add base item',
  test_animation_btn_reset_scene: 'Reset scene',
  test_animation_btn_base: 'Base',
  trigger_object_lbl_set_triggers: 'Setting triggers',
  trigger_object_lbl_state: 'State',
  trigger_object_lst_state_on: 'On',
  trigger_object_lst_state_off: 'Off',
  trigger_object_lst_state_warning: 'Warnig',
  trigger_object_lst_state_critical: 'Critical',
  trigger_object_lbl_icon: 'Icon',
  trigger_object_lbl_effects: 'effects',
  trigger_object_lbl_attack_down: 'Attack down',
  trigger_object_lbl_progressbar_yellow: 'ProgressbarYellow',
  trigger_object_lbl_progressbar_red: 'ProgressbarRed',
  trigger_object_lbl_network_scanning: 'NetworkScanning',
  trigger_object_lbl_vunerabilities_scanning: 'VunerabilitiesScanning',
  trigger_object_lbl_gif_effects: 'gif effects',
  trigger_object_lbl_name: 'Name',
  trigger_object_lbl_no_more_than_64_characters: 'No more than 64 characters',
  trigger_object_lbl_condition: 'Condition',
  trigger_object_lbl_no_more_than_1024_characters: 'No more than 1024 characters',
  trigger_object_lbl_incorrect_format: 'Incorrect format',
  trigger_object_lbl_required_field: 'Mandatory field missed',
  trigger_object_lbl_no_triggers: 'Triggers are not defined',
  trigger_object_btn_new: 'New',
  trigger_object_btn_delete: 'Delete',
  trigger_object_hdr_name_column: 'Name',
  trigger_object_hdr_state_column: 'State',
  trigger_object_hdr_effects_column: 'Effects',
  trigger_object_hdr_gif_effects_column: 'Gif effects',
  trigger_object_hdr_icon_column: 'Icon',
  trigger_object_btn_save: 'Save',
  trigger_object_btn_set_triggers: 'Set triggers',
  trigger_object_btn_copy: 'Copy',
  trigger_object_btn_paste: 'Paste',
  trigger_object_btn_duplicate: 'Duplicate',
  trigger_line_lbl_set_triggers: 'Setting triggers',
  trigger_line_lbl_effects: 'effects',
  trigger_line_lbl_AdditionalScanBlue: 'AdditionalScanBlue',
  trigger_line_lbl_AdditionalScanGreen: 'AdditionalScanGreen',
  trigger_line_lbl_CriticalSuccessfulAttack: 'CriticalSuccessfulAttack',
  trigger_line_lbl_UserSettings: 'UserSettings',
  trigger_line_lbl_WarningNetworkScan: 'WarningNetworkScan',
  trigger_line_lbl_width: 'Width',
  trigger_line_lbl_required_field_width: 'Required field',
  trigger_line_lbl_color: 'Color',
  trigger_line_lbl_name: 'Name',
  trigger_line_lbl_no_more_than_64_characters: 'No more than 64 characters',
  trigger_line_lbl_condition: 'Condition',
  trigger_line_lbl_no_more_than_1024_characters: 'No more than 1024 characters',
  trigger_line_lbl_required_field: 'Required field',
  trigger_line_lbl_incorrect_format: 'Incorrect format',
  trigger_line_btn_new: 'New',
  trigger_line_btn_delete: 'Delete',
  trigger_line_hdr_name: 'Name',
  trigger_line_hdr_effects: 'Effects',
  trigger_line_hdr_width: 'Width',
  trigger_line_hdr_color: 'Color',
  trigger_line_btn_save: 'Save',
  trigger_line_btn_set_triggers: 'Set triggers',
  trigger_line_btn_copy: 'Copy',
  trigger_line_btn_paste: 'Paste',
  trigger_line_btn_duplicate: 'Duplicate',
  trigger_line_btn_close: 'Close',
  trigger_scene_lbl_set_triggers: 'Setting triggers',
  trigger_scene_lbl_effects: 'Effects',
  trigger_scene_lbl_lose: 'Lose',
  trigger_scene_lbl_progressbaryellow: 'ProgressbarYellow',
  trigger_scene_lbl_progressbarred: 'ProgressbarRed',
  trigger_scene_lbl_notification: 'Notification',
  trigger_scene_lbl_no_more_than_128_characters: 'No more than 128 characters',
  trigger_scene_lbl_notification_color: 'Notification color',
  trigger_scene_lbl_name: 'Namе',
  trigger_scene_lbl_no_more_than_64_characters: 'No more than 64 characters',
  trigger_scene_lbl_condition: 'Condition',
  trigger_scene_lbl_no_more_than_1024_characters: 'No more than 1024 characters',
  trigger_scene_lbl_required_field: 'Required field',
  trigger_scene_lbl_incorrect_format: 'Incorrect format',
  trigger_scene_btn_new: 'New',
  trigger_scene_btn_delete: 'Delete',
  trigger_scene_hdr_name: 'Name',
  trigger_scene_hdr_effects: 'Effects',
  trigger_scene_hdr_notification: 'Notification',
  trigger_scene_hdr_color: 'Color',
  trigger_scene_btn_save: 'Save',
  trigger_scene_btn_set_triggers: 'Set triggers',
  trigger_scene_btn_copy: 'Copy',
  trigger_scene_btn_paste: 'Paste',
  trigger_scene_btn_duplicate: 'Duplicate',
  trigger_scene_btn_close: 'Close',
  trigger_background_lbl_set_triggers: 'Setting triggers',
  trigger_background_lbl_background_color: 'Background color',
  trigger_background_lbl_stroke_color: 'Stroke color',
  trigger_background_lbl_name: 'Namе',
  trigger_background_lbl_no_more_than_64_characters: 'No more than 64 characters',
  trigger_background_lbl_condition: 'Condition',
  trigger_background_lbl_no_more_than_1024_characters: 'No more than 1024 characters',
  trigger_background_lbl_required_field: 'Required field',
  trigger_background_lbl_incorrect_format: 'Incorrect format',
  trigger_background_btn_new: 'New',
  trigger_background_btn_delete: 'Delete',
  trigger_background_hdr_name: 'Name',
  trigger_background_hdr_background_color: 'Background color',
  trigger_background_hdr_stroke_color: 'Stroke color',
  trigger_background_btn_save: 'Save',
  trigger_background_btn_set_triggers: 'Set triggers',
  trigger_background_btn_copy: 'Copy',
  trigger_background_btn_paste: 'Paste',
  trigger_background_btn_duplicate: 'Duplicate',
  trigger_background_btn_close: 'Close',
  trigger_text_lbl_set_triggers: 'Setting triggers',
  trigger_text_lbl_text: 'Text',
  trigger_text_lbl_no_more_than_128_characters: 'No more than 128 characters',
  trigger_text_lbl_required_field_text: 'Required field',
  trigger_text_lbl_size: 'Font size',
  trigger_text_lbl_size_required_field_size: 'Required field',
  trigger_text_lbl_color: 'Text color',
  trigger_text_lbl_name: 'Namе',
  trigger_text_lbl_no_more_than_64_characters: 'No more than 64 characters',
  trigger_text_lbl_condition: 'Condition',
  trigger_text_lbl_no_more_than_1024_characters: 'No more than 1024 characters',
  trigger_text_lbl_required_field: 'Required field',
  trigger_text_lbl_incorrect_format: 'Incorrect format',
  trigger_text_btn_new: 'New',
  trigger_text_btn_delete: 'Delete',
  trigger_text_hdr_name: 'Name',
  trigger_text_hdr_text: 'Text',
  trigger_text_hdr_size: 'Size',
  trigger_text_hdr_color: 'Color',
  trigger_text_btn_save: 'Save',
  trigger_text_btn_set_triggers: 'Set triggers',
  trigger_text_btn_copy: 'Copy',
  trigger_text_btn_paste: 'Paste',
  trigger_text_btn_duplicate: 'Duplicate',
  trigger_text_btn_close: 'Close',
  trigger_notification_save_hdr_set_triggers: 'Setting triggers',
  trigger_notification_save_lbl_text: 'Save changes?',
  trigger_notification_save_btn_save: 'Save',
  trigger_notification_save_btn_don_t_save: 'Don`t save',
  trigger_notification_save_btn_cancel: 'Cancel',
  trigger_notification_delete_hdr_set_triggers: 'Setting triggers',
  trigger_notification_delete_lbl_text: 'Delete item?',
  trigger_notification_delete_btn_delete: 'Delete',
  trigger_notification_delete_btn_cancel: 'Cancel',
  trigger_notification_error_hdr_set_triggers: 'The triggers contain errors:',
  trigger_notification_error_lbl_text: 'Error in trigger',
  trigger_notification_error_btn_close: 'Close',
  properties_panel_properties_scene_edit_message: 'Message',
  add_object_lbl_no_image: 'No image',
  panel_objects_lbl_nothing_found: 'Nothing found',
  error_load_lbl_error_text: 'Error opening scene',
  error_load_lbl_Duplicated_IDs: 'Duplicate ID',
  add_gif_lbl_saved: 'Saved',
  trigger_notification_delete_lbl_text_items: 'Delete items?',
  trigger_text_btn_set_trigger: 'Set trigger',
  test_animation_lbl_modifyng_object: 'Modifyng object',
  properties_panel_object_lbl_test_mode: 'Test mode',
  trigger_object_btn_create_state: 'Add state',
  trigger_scene_hdr_standart_effects: 'Standard effects',
  trigger_text_hdr_name_lbl: 'Trigger',
  trigger_object_lbl_context_icon: 'Context icon',
  trigger_panel_object_lbl_gif_effects: 'GIF animation',
  trigger_panel_select_plh: 'Choose an effect',
  trigger_scene_hdr_effect: 'Standard effects',
  trigger_essence_hdr_effect: 'Effects',
  trigger_line_hdr_effect: 'Effects',
  trigger_scene_lbl_color: 'Line color',
  trigger_popup_reset_triggers_hdr: 'Reset changes',
  trigger_popup_delete_triggers_hdr: 'Removing a trigger',
  trigger_notification_save_text: 'Are you sure you want to reset your changes?',
  trigger_notification_save_btn_reset: 'Reset',
  trigger_notification_delete_text_items: 'The selected state will be deleted. Are you sure you want to delete the trigger?',
  trigger_notification_reset_first_line: 'Triggers have been changed.\n Closing the window will result in data loss.',
  trigger_notification_reset_second_line: 'Are you sure you want to reset your changes?',
  trigger_scene_label_notification: 'Text in the scene',
  trigger_text_plh_name: 'Enter trigger name'
}
