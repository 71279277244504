<template>
  <div class="complexity-picker" :class="{ 'complexity-picker__error': errorHint, small }">
    <span class="complexity-picker__label">
      <CbrHint
        v-if="required && errorHint"
        :title="errorHint.title"
        :sub-title="errorHint.subTitle"
        :description="errorHint.description"
        error
        left
      >
        <CbrIcon> mdi-alert-circle </CbrIcon>
      </CbrHint>
      {{ props.label }}
      <span class="complexity-picker__required" v-if="required">*</span>
    </span>
    <div
      class="complexity-picker__stars-container"
      :class="{
        'complexity-picker__stars-container--disabled': props.disabled
      }"
    >
      <CbrIcon
        v-for="star in 5"
        :key="star"
        :color="getStarsColor"
        @click="onStarClick(star)"
      >
        {{ `mdi-star${props.value >= star ? '' : '-outline'}` }}
      </CbrIcon>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import colorsHelper from '@/helpers/colors.helper'

const props = defineProps({
  value: {
    type: Number,
    default: 0
  },
  label: {
    type: String,
    required: false
  },
  small: Boolean,
  required: Boolean,
  errorHint: Object,
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['input'])

function onStarClick (star) {
  if (props.disabled) {
    return
  }
  emit('input', star)
}

const getStarsColor = computed(() => {
  return props.disabled ? colorsHelper.secondaryColors.$borderDisabled : colorsHelper.baseColors.$ActionColor
})
</script>

<style scoped lang="scss">
.complexity-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  height: 80px;
  gap: 4px;
  &__label {
    display: inline-flex;
    gap: 4px;
    color: $ActionColor;
    font-size: 1.125rem;
    z-index: 1;
  }
  &__stars-container {
    display: flex;
    &--disabled {
      * {
        cursor: default !important;
      }
    }
  }
  &__required {
    color: $base-color-error-text;
  }
  &.complexity-picker__error {
    ::v-deep * {
      color: $base-color-error-text !important;
    }
    .mdi-alert-circle {
      cursor: default;
    }
  }
  &.small {
    min-width: 105px;
    height: 21px;
    ::v-deep .v-icon {
      font-size: 21px;
    }
  }
}
</style>
