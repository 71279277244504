<template>
  <v-tooltip
    :right="right"
    :left="left"
    :top="top"
    :bottom="bottom"
    :z-index="zIndex"
    :open-delay="openDelay"
    :content-class="contentClass"
    :disabled="disabled"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on }">
      <slot
        v-if="customActivator"
        v-bind="{ on }"
      />
      <div v-else v-on="on" class="d-flex">
        <slot />
      </div>
    </template>
    <div
      v-if="!hideHint"
      class="cbr-hint"
      :class="{ 'd-flex': !hasTitle }"
      :style="{ 'max-height': maxHeight, 'max-width': maxWidth }"
    >
      <div class="cbr-hint-title" v-if="hasTitle || !noIcon">
        <CbrIcon v-if="!noIcon" class="cbr-hint-icon" :class="{ 'cbr-hint-icon-error': error }">
          {{ getIcon }}
        </CbrIcon>
        <div class="cbr-hint-title-group" v-if="hasTitle">
          <div
            class="cbr-hint-title-text"
            :class="{ 'cbr-hint-title-text-error': error, 'cbr-hint-title-text-warning': warning }"
            :style="{ 'max-width': calcTitleMaxWidth }"
          >
            {{ title }}
          </div>
          <div
            class="cbr-hint-subTitle-text"
            :class="{ 'cbr-hint-subTitle-text-warning': warning}"
          >
            {{ subTitle }}
          </div>
        </div>
        <div v-else-if="(error || description) && noTitle" class="cbr-hint-title-group">
          <div class="cbr-hint-description">
            {{ description }}
          </div>
        </div>
        <div v-else-if="error && !$slots.title && !$slots.subtitle" class="cbr-hint-title-group">
          <div class="cbr-hint-title-text text-error">
            {{ $t('common_error_title') }}
          </div>
        </div>
        <div v-else class="cbr-hint-title-group">
          <slot name="title"></slot>
          <slot name="subtitle"></slot>
        </div>
      </div>
      <div
        v-if="description && !noTitle"
        class="cbr-hint-description"
        :class="{ 'cbr-hint-p10': hasTitle }"
      >
        {{ description }}
      </div>
      <slot name="description"></slot>
    </div>
  </v-tooltip>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  noIcon: {
    type: Boolean,
    default: false
  },
  noTitle: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  right: {
    type: Boolean,
    default: false
  },
  left: {
    type: Boolean,
    default: false
  },
  top: {
    type: Boolean,
    default: false
  },
  bottom: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false,
  },
  warning: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Boolean,
    default: true
  },
  openDelay: {
    type: Number,
    default: 500
  },
  zIndex: {
    type: [Number, String],
    default: undefined
  },
  maxWidth: {
    type: String,
    default: '500px'
  },
  maxHeight: {
    type: String,
    default: '400px'
  },
  contentClass: {
    type: String,
    default: ''
  },
  customActivator: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  hideHint: {
    type: Boolean,
    default: false
  }
})

const hasTitle = computed(() => !!(props.title || props.subTitle))
const getIcon = computed(() => {
  if (props.error) return '$error_hint'
  if (props.warning) return '$warning_hint'
  return '$info_hint'
})
const calcTitleMaxWidth = computed(() => `calc(${props.maxWidth} - ${props.noIcon ? 0 : 32}px - 20px)`)
</script>

<style lang="scss" scoped>
.cbr-hint {
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid rgba($base-color, 0.25);
  background: rgba($PopupDarkBack, 0.5);
  box-shadow: 2px 2px 4px rgba($base-black, 0.25);
  backdrop-filter: blur(50px);
  word-wrap: break-word;
  flex-direction: column;
  gap: 10px;

  &-icon {
    min-width: 22px;
    min-height: 22px;
    margin-right: 10px;
    ::v-deep circle {
      fill: none;
    }
    &-error {
      ::v-deep path {
        fill: $ErrorColor;
      }
      ::v-deep circle {
        stroke: $ErrorColor;
      }
    }
  }
  &-title-group {
    font-family: $primary-font-family-medium;
    min-height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-title {
    display: flex;
    align-items: center;
  }
  &-title-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    color: $base-color;
    &-error {
      color: $base-color-light;
    }
    &-warning {
      color: $WarningColor;
    }
    &.text-error {
      color: $base-color-error;
    }
  }
  &-subTitle-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    color: $base-color;
    &-warning {
      color: $WarningColor;
    }
  }
  &-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-align: left;
    color: $base-color-light;
    white-space: pre-line;
  }
  &-p10 {
    padding-top: 10px;
  }
  ::v-deep .email-text {
    color: $base-color;
  }
}
.v-tooltip__content {
  padding: 0;
  opacity: 1 !important;
  background: none;
}
.move-left {
  transform: translateX(-50%);
}
.move-right {
  transform: translateX(50%);
}
</style>
