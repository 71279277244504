import httpClient from './http'
import main from '@/helpers/main.helper'
import { i18n } from '@/i18n-setup'

const END_POINT = '/mission'
export default {
  getMissions (page, size = 10, name = '') {
    return httpClient.get(END_POINT, {
      params: {
        page,
        size,
        name
      }
    })
  },
  getMission (id) {
    return httpClient.get(`${END_POINT}/${id}/info`)
      .catch(error => {
        if (error.response.status === 404) {
          main.show404ErrorPage()
        }
      })
  },
  getMissionConfig (id) {
    return httpClient.get(`${END_POINT}/${id}/config`)
  },
  getMissionAttributes (id) {
    return httpClient.get(`${END_POINT}/${id}/attributes`)
  },
  getMissionMaterials (id) {
    return httpClient.get(`${END_POINT}/${id}/media/list?isEvent=false`)
  },
  getMissionMaterialsZipAsBlob (id) {
    return httpClient.get(`${END_POINT}/${id}/media`, {
      responseType: 'blob'
    })
  },
  hasNetworkMap (id) {
    return httpClient.head(`${END_POINT}/${id}/media/net-map?locale=${i18n.locale}`)
  },
  getNetworkMapAsBlob (id) {
    const lang = i18n.locale
    return httpClient.get(`${END_POINT}/${id}/media/net-map?locale=${lang}`, {
      responseType: 'blob'
    })
  },
  getMissionMaterialsFileAsBlob (id, name) {
    const lang = i18n.locale
    return httpClient.get(`${END_POINT}/${id}/media/file/${name}?locale=${lang}`, {
      responseType: 'blob'
    })
  },
  getMissionMaterialsFile (id, name) {
    const lang = i18n.locale
    const uri = `/api${END_POINT}/${id}/media/file/${name}?locale=${lang}`
    window.open(uri, '_blank')
  },
  importMission (id, form) {
    return httpClient.post(`${END_POINT}/${id}`, form)
  },
  exportMission (id) {
    return httpClient.get(`${END_POINT}/${id}`, {
      responseType: 'blob'
    })
  },
  removeMission (id) {
    return httpClient.delete(`${END_POINT}/${id}`)
  },
}
