import Vue from 'vue'
import Vuex from 'vuex'

import account from './account'
import map from './map'
import sse from './sse'
import global from './global'
import lk from './lk'
import icons from './icons'
import quest from './quest'

Vue.use(Vuex)

const modules = {
  account, global, map, sse, lk, icons, quest
}

const store = new Vuex.Store({ modules })
export default store

export const useStore = () => store
