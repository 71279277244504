import Icon from '@/components/icons/Icon'
import colors from 'vuetify/es5/util/colors'
import admin from '@/plugins/vuetify/modules/icons/admin'
import chat from '@/plugins/vuetify/modules/icons/chat'
import mission from '@/plugins/vuetify/modules/icons/mission'
import task from '@/plugins/vuetify/modules/icons/task'
import event from '@/plugins/vuetify/modules/icons/event'
import uikit from '@/plugins/vuetify/modules/icons/uikit'
import tools from '@/plugins/vuetify/modules/icons/tools'
import quest from '@/plugins/vuetify/modules/icons/quest'

const index = { values: {} }
const data = {
  ...uikit,
  ...chat,
  ...admin,
  ...mission,
  ...task,
  ...event,
  ...tools,
  ...quest,
  filter: {},
  filter_dark: {
    iconName: 'filter',
    fill: '#01715E'
  },
  clear_filter: {
    fill: colors.red.lighten3
  },
  teacher_curated: {
    iconName: 'lotus_position',
    fill: 'currentColor'
  },
  sort_active: {
    iconName: 'sort',
    stroke: '#FFFFFFB2',
    fill: 'transparent'
  },
  sort: {
    stroke: colors.grey.lighten1,
    fill: 'transparent'
  },
  sort_inactive: {
    iconName: 'sort',
    stroke: '#FFFFFF4D',
    fill: 'transparent'
  },
  info_for_mission: {},
  info_fill: {},
  attention: {},
  event_edit: {},
  vector: {},
  corona: {},
  btn_scoreboard: {},
  ru: {},
  eng: {},
  reference_report: {},
  change: {},
  change_label: {},
  new_label: {},
  change_label_en: {},
  new_label_en: {},
  triangle_left_changed: {},
  logo_login: {},
  logo_new: {},
  logo_new_en: {},
  logo_solar: {},
  logo_solar_cyber_ru: {},
  logo_solar_cyber_en: {},
  new: {},
  select_events: {},
  start_infrastructure: {},
  console: {},
  white_console: {
    iconName: 'console',
    fill: 'white'
  },
  circle_error: {},
  circle_error_excl: {},
  event_wait: {
    iconName: 'clock',
    initFill: true
  },
  event_ok: {
    iconName: 'circle_ok',
    initFill: true
  },
  event_loading: {
    initFill: true
  },
  event_type_training: {},
  event_type_opposition: {},
  event_type_flag: {},
  shield: {},
  bug: {},
  clear_text: {
    initFill: true
  },
  up: {},
  down: {},
  right_chevron_borders: {},
  profile: {
    initFill: true
  },
  teacher: {},
  self_learning: {
    initFill: true
  },
  observer: {
    initFill: true
  },
  run_event: {},
  scoring: {
    initFill: true
  },
  download_material: {
    iconName: 'download_material',
    fill: 'currentColor'
  },
  download_csv: {
    iconName: 'download_material'
  },
  download_lms: {
    iconName: 'download_material',
    fill: '#00F0FF'
  },
  download_report: {
    iconName: 'download_w'
  },
  pdf: {},
  csv: { initFill: true },
  upload: {
    fill: 'currentColor'
  },
  vm: {},
  reloadVm: {},
  reload: {},
  reload2: {},
  log_menu: {},
  last_report: {
    initFill: true
  },
  topology: {},
  event_materials: {},
  log: {},
  log_top_menu: {},
  slot: {},
  score: {},
  score_green: {
    iconName: 'score',
    fill: '#00FFC2'
  },
  event_target: {},
  report: {},
  report_grey: {},
  send_envelope: {},
  reports_list: {},
  users_group: {
    initFill: true
  },
  users_group_b: {},
  monitor: {},
  briefcase: {},
  user_item: {
    initFill: true
  },
  user_item_b: {},
  hollow_shield: {},
  battle: {},
  hollow_star: {},
  hollow_clock: {},
  hollow_clock_2: {},
  hollow_clock_12: {},
  lk_face: {},
  lk_exp: {},
  cup_ctf: {},
  cup_res: {},
  cup_def: {},
  cup_sq: {},
  lk_flag: {},
  lk_ioc: {},
  lk_srv_en: {},
  lk_srv_dis: {},
  lk_task: {},
  lk_type_ctf: {},
  lk_type_res: {},
  lk_type_def: {},
  lk_type_sq: {},
  lk_uses: {},
  lk_statuses: {},
  lk_empty_person: {},
  lk_empty_team: {},
  calendar: {},
  finish: { fill: '#00FFC2' },
  finish_red: {
    iconName: 'finish',
    fill: '#FF6969'
  },
  checkbox_check: {
    fill: 'transparent'
  },
  checkbox_uncheck: {
    fill: 'transparent'
  },
  check: {
    fill: 'transparent'
  },
  check_wo_pad: {},
  event_err: {},
  run: {},
  loading: {},
  event_users: {},
  cbr_event_users: {},
  cbr_event_team: {},
  cbr_event_teacher: {},
  cbr_event_observer: {},
  cbr_copy: {},
  cbr_checker: {},
  shield_vs_bug: {},
  mission_info: {},
  crib: {},
  info: {},
  info_light: {},
  info_hint: {},
  error_hint: {},
  warning_hint: {},
  info_event: {},
  mission_map: {},
  mission_map_3d: {},
  mission_map_3d_re: {},
  mission_materials: {},
  import: {},
  export_mission: {},
  import_mission: {},
  export: {},
  breadcrumb_triangle: {},
  calendar_menu: {},
  play: {},
  console_menu: {},
  score_cbr: {},
  bots_menu: {},
  pencil: {},
  plus: {},
  plus_gray: {
    iconName: 'plus',
    fill: 'rgba(128, 128, 128, 0.9)'
  },
  plus_darkgray: {
    iconName: 'plus',
    fill: 'rgba(19, 19, 19, 0.9)'
  },
  plus_green: {
    iconName: 'plus',
    fill: '#00FFC2'
  },
  save: {},
  envelope_email: {},
  envelope: {},
  notification_envelope: {},
  notification_sended: {},
  add_plus: {},
  add_plus_green: {
    iconName: 'add_plus',
    fill: '#00FFC2'
  },
  add_script: {},
  content_copy: {},
  mission_filter_star: {},
  rhomb_list_marker: {},
  export_to_doc: {},
  comment_tooltip: {},
  comment_score: {},
  dialog_corner: {},
  user_edit: {},
  question: {},
  checked_monitor: {},
  triangle_left: {},
  triangle_left_edge: {},
  triangle_right: {},
  expand_minus: {},
  expand_plus: {},
  calendar_1: {},
  star_plus: {},
  human: {},
  report_star: {},
  event_teacher_error: {},
  event_teacher_notification: {},
  flag: {},
  back_triangle: {},
  back_triangle_mission: {
    iconName: 'back_triangle',
    fill: 'rgba(0, 240, 255, 0.8)'
  },
  exit: {},
  send: {},
  new_version: {},
  update_data: {},
  send_disabled: {
    fill: '#ffffff80'
  },
  send_enabled: {
    fill: '#0A222F',
  },
  updown: {
  },
  lock: {},
  arrow_circle: {},
  info_ioc: {},
  delete_img: {},
  delete_trash_can: {},
  download_file: {},
  lamp: {},
  basket: {},
  update: {},
  module: {},
  warning: {},
  trash_can: {},
  key_attack: {},
  subtract: {},
  tab_account_info: {
    iconName: 'account_info'
  },
  tab_account_events: {
    iconName: 'account_events'
  },
  tab_account_results: {
    iconName: 'account_results'
  },
  tab_audit: {
    iconName: 'audit',
    initFill: true
  },
  tab_integrity: {
    iconName: 'integrity',
    initFill: true
  },
  indicator_lamp: {},
  pause_event: {
    iconName: 'pause_event'
  },
  continue_event: {
    iconName: 'continue_event'
  },
  event_start: {}
}

Object.keys(data).forEach(key => {
  const props = data[key]

  if (!props.iconName) {
    props.iconName = key
  }

  index.values[key] = {
    component: Icon,
    props: props
  }
})

export default index
