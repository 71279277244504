<template>
  <div class="control-menu d-flex">
    <v-col
      cols="2"
      class="d-flex offset-md-5 justify-center pa-0 flex-grow-1 flex-shrink-0"
      v-if="(isAdmin || isTeacher) && !isSummingUpResult"
    >
      <EventControlBtn
        id="page-main-header-event_start"
        :disabled="isStart"
        :class="{ 'active' : isStart }"
        @click="startEvent()"
      >
        <template v-slot:event-control-svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            version="1.1"
            id="svgStart"
          >
            <path
              d="M10,246 V10 l246,128 L10,246 z"
            />
          </svg>
        </template>
      </EventControlBtn>
      <v-divider
        class="divider"
        vertical
      />
      <EventControlBtn
        id="page-main-header-event_pause"
        :disabled="isPause || isPausePreparation"
        :class="{ 'active' : isPause || isPausePreparation}"
        @click="pauseEvent()"
      >
        <template v-slot:event-control-svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            version="1.1"
            id="svgPause"
          >
            <rect
              x="15px"
              y="10px"
              width="78"
              height="236"
            />
            <rect
              x="163px"
              y="10px"
              width="78"
              height="236"
            />
          </svg>
        </template>
      </EventControlBtn>
      <v-divider
        class="divider"
        vertical
      />
      <div class="stop-btn-container">
      <EventControlBtn
        id="page-main-header-event_stop"
        :disabled="isStop"
        :class="{ 'active' : isStop }"
        @click="isShowStopEventDialog = true"
      >
        <template v-slot:event-control-svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            version="1.1"
            id="svgStop"
          >
            <rect
              x="10px"
              y="10px"
              width="236"
              height="236"
            />
          </svg>
        </template>
      </EventControlBtn>
    </div>
      <WarningDialog
        :title="$t(events.isPreFinishState(props.status) ? 'event_creation_editing_btn_event_completion' : 'event_card_hdr_cancel_event')"
        :first-line-text="$t(events.isPreFinishState(props.status) ? 'event_popup_event_end_txt_end' : 'event_card_hdr_cancel_event_sure')"
        second-line-text=' '
        v-model="isShowStopEventDialog"
      >
        <template slot="footer">
          <CbrButton
            error
            :text="$t('event_card_btn_finish')"
            @click="stopEvent()"
          />
          <CbrButton
            :text="$t('event_card_btn_cancel')"
            @click="isShowStopEventDialog = false"
          />
        </template>
      </WarningDialog>
    </v-col>
    <v-col
      cols="5"
      class="d-flex pa-0 flex-grow-0"
      :class="{ 'justify-center': !(isAdmin || isTeacher)}"
    >
      <template
        v-if="isPausePreparation"
      >
        <div
          class="status-button"
        >
          <div
            class="status-button-in"
          >
            {{ pauseText }}
          </div>
        </div>
      </template>
      <div
        class="status-button"
        v-if="(isUser || isObserver) && isPause"
      >
        <div
          class="status-button-in active"
        >
          {{ $t('event_card_lbl_pause_state') }}
        </div>
      </div>
    </v-col>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import EventControlBtn from '@/components/EventControlBtn'
import { useStore } from '@/store'
import events from '@/helpers/events.helper'
import { useRoute } from 'vue-router/composables'
import eventService, { CTF, DEFENCE, RESISTANCE } from '@/services/event.service'
import ctfService from '@/services/ctf.service'

const props = defineProps({
  status: String,
  title: String,
  gameType: String
})

const store = useStore()
const route = useRoute()

const isShowStopEventDialog = ref(false)

const isAdmin = store.getters['account/isAdmin']

const isTeacher = store.getters['account/isTeacher']

const isObserver = store.getters['account/isObserver']

const isUser = store.getters['account/isUser']

const isStart = computed(() => props.status === events.RUNNING.key)
const isPause = computed(() => props.status === events.PAUSED.key)
const isSummingUpResult = computed(() => props.status === events.FINISHING.key)
const isStop = computed(() => props.status === events.CANCELED.key)
const pauseText = computed(() => events.getStatusName(events.PREPARATION_PAUSE.key))
const isPausePreparation = computed(() => props.status === events.PREPARATION_PAUSE.key)
const startEvent = () => {
  eventService.manualStartEvent(route.params.eventId, props.gameType)
}
const emit = defineEmits(['changeStatus'])
const pauseEvent = async () => {
  store.commit('global/setBtnPause', true)
  await eventService.manualPauseEvent(route.params.eventId, props.gameType)
  emit('changeStatus')
}

const stopEvent = async () => {
  isShowStopEventDialog.value = false
  if (props.gameType === DEFENCE) {
    await eventService.manualStopEvent(route.params.eventId, props.gameType)
  }
  if ([CTF, RESISTANCE].includes(props.gameType)) {
    if ([events.PLANNED.key, events.READY.key, events.ERROR.key].includes(props.status)) {
      await ctfService.cancelEvent(props.gameType, route.params.eventId)
    }
    if ([events.RUNNING.key, events.PAUSED.key].includes(props.status)) {
      await ctfService.debriefEvent(props.gameType, route.params.eventId)
    }
    if ([events.FINISHING.key].includes(props.status)) {
      await ctfService.finishEvent(props.gameType, route.params.eventId)
    }
  }
  emit('changeStatus')
}
</script>

<style lang="scss" scoped>
.control-menu {
  width: 100%;
  display: flex;
  justify-content: center;
}

.status-button {
  max-width: 250px;
  clip-path: polygon(
    8px 0,
    0 8px,
    0 100%,
    calc(100% - 8px) 100%,
    100% calc(100% - 8px),
    100% 0
  );
  background-color: rgba(0, 240, 255, 0.3);
  &-in {
    min-width: 200px;
    background-color: #0B2A3A;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: rgba(0, 240, 255, 0.3);
    padding: 1px 15px 1px 15px;
    clip-path: polygon(
      9px 1px,
      1px 9px,
      1px calc(100% - 1px),
      calc(100% - 9px) calc(100% - 1px),
      calc(100% - 1px) calc(100% - 9px),
      calc(100% - 1px) 1px,
      9px 1px
    );
  }
}

.active {
  color: #00FFC2;
}

.stop-btn-container {
  position: relative;
  z-index: 10;
}

</style>
