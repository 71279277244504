import { render, staticRenderFns } from "./Materials.vue?vue&type=template&id=6a96c492&scoped=true"
import script from "./Materials.vue?vue&type=script&setup=true&lang=js"
export * from "./Materials.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Materials.vue?vue&type=style&index=0&id=6a96c492&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a96c492",
  null
  
)

export default component.exports