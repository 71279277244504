import Vue from 'vue'
import Icon from '@/components/icons/Icon'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog'
import WarningDialog from '@/components/dialogs/WarningDialog'
import SuccessDialog from '@/components/dialogs/SuccessDialog'
import Preloader from '@/components/Preloader'
// UI kit components
import CbrBreadCrumbs from '@/components/CbrBreadCrumbs'
import CbrBtnToggle from '@/components/kit/CbrBtnToggle'
import CbrButton from '@/components/kit/CbrButton'
import CbrCheckBox from '@/components/kit/CbrCheckBox'
import CbrSortPicker from '@/components/kit/CbrSortPicker'
import CbrSwitch from '@/components/kit/CbrSwitch'
import CbrHint from '@/components/kit/CbrHint'
import CbrContentBlock from '@/components/kit/CbrContentBlock'
import CbrTextArea from '@/components/kit/CbrTextArea'
import CbrTextField from '@/components/kit/CbrTextField'
import CbrSpinner from '@/components/kit/CbrSpinner'
import CbrFileInput from '@/components/kit/CbrFileInput'
import CbrFilesList from '@/components/kit/CbrFilesList'
import CbrExpansionPanelOutlined from '@/components/kit/CbrExpansionPanelOutlined.vue'
import CbrExpansionPanelBase from '@/components/kit/CbrExpansionPanelBase.vue'
import CbrDialog from '@/components/kit/CbrDialog.vue'
import CbrCombobox from '@/components/kit/CbrCombobox.vue'
import CbrPagination from '@/components/kit/CbrPagination.vue'
import CbrTabs from '@/components/kit/CbrTabs.vue'
import CbrAutocomplete from '@/components/kit/CbrAutocomplete.vue'
import CbrTooltip from '@/components/kit/CbrTooltip.vue'
import CbrFilesBlock from '@/components/kit/CbrFilesBlock.vue'
import CbrDatePicker from '@/components/kit/CbrDatePicker.vue'
import CbrForm from '@/components/kit/CbrForm.vue'
import CbrIcon from '@/components/kit/CbrIcon.vue'
import CbrSplitPanel from '@/components/kit/CbrSplitPanel.vue'
import CbrDataTable from '@/components/kit/CbrDataTable.vue'
import CbrCarousel from '@/components/kit/CbrCarousel.vue'
import CbrComplexityPicker from '@/components/kit/CbrComplexityPicker.vue'
import CbrSlotSelector from '@/components/kit/CbrSlotSelector.vue'
import CbrMaterials from '@/components/kit/CbrMaterials.vue'

export default {
  install () {
    Vue.component('Icon', Icon)
    Vue.component('ConfirmDialog', ConfirmDialog)
    Vue.component('WarningDialog', WarningDialog)
    Vue.component('SuccessDialog', SuccessDialog)
    Vue.component('Preloader', Preloader)
    // UI kit components
    Vue.component('CbrBreadCrumbs', CbrBreadCrumbs)
    Vue.component('CbrBtnToggle', CbrBtnToggle)
    Vue.component('CbrButton', CbrButton)
    Vue.component('CbrCheckBox', CbrCheckBox)
    Vue.component('CbrSortPicker', CbrSortPicker)
    Vue.component('CbrSwitch', CbrSwitch)
    Vue.component('CbrHint', CbrHint)
    Vue.component('CbrCombobox', CbrCombobox)
    Vue.component('CbrContentBlock', CbrContentBlock)
    Vue.component('CbrTextArea', CbrTextArea)
    Vue.component('CbrTextField', CbrTextField)
    Vue.component('CbrSpinner', CbrSpinner)
    Vue.component('CbrFileInput', CbrFileInput)
    Vue.component('CbrFilesList', CbrFilesList)
    Vue.component('CbrExpansionPanelOutlined', CbrExpansionPanelOutlined)
    Vue.component('CbrExpansionPanelBase', CbrExpansionPanelBase)
    Vue.component('CbrDialog', CbrDialog)
    Vue.component('CbrPagination', CbrPagination)
    Vue.component('CbrTabs', CbrTabs)
    Vue.component('CbrAutocomplete', CbrAutocomplete)
    Vue.component('CbrTooltip', CbrTooltip)
    Vue.component('CbrFilesBlock', CbrFilesBlock)
    Vue.component('CbrDatePicker', CbrDatePicker)
    Vue.component('CbrForm', CbrForm)
    Vue.component('CbrIcon', CbrIcon)
    Vue.component('CbrSplitPanel', CbrSplitPanel)
    Vue.component('CbrComplexityPicker', CbrComplexityPicker)
    Vue.component('CbrSlotSelector', CbrSlotSelector)
    Vue.component('CbrDataTable', CbrDataTable)
    Vue.component('CbrMaterials', CbrMaterials)
    Vue.component('CbrCarousel', CbrCarousel)
  }
}
