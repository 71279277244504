import accountService from '../../services/account.service'
import router from '../../router'
import usersService from '../../services/user.service'
import jwt_decode from 'jwt-decode'

const actions = {
  async logout ({ commit }) {
    if (router.currentRoute.path !== '/logout') {
      await router.push('/logout')
    } else {
      accountService.logout().then(res => {
        commit('logout')
        commit('global/setCurrentEvent', null, { root: true })
        commit('quest/setCurrentQuestPath', null, { root: true })
        commit('quest/setCurrentTaskInfo', null, { root: true })

        if (router.currentRoute.path !== '/login') {
          router.push('/login')
        }
      }).catch(() => {
        localStorage.setItem('logoutStatus', 'failed')
        commit('logout')
        if (router.currentRoute.path !== '/appError') {
          router.push('/login')
        }
      }).finally(() => {
        localStorage.removeItem('events-cache')
        sessionStorage.clear()
      })
    }
  },
  retrieveUser ({ commit }) {
    return usersService.getCurrentUser(true).then(user => {
      const results = document.cookie.match('(^|;) ?' + 'AuthToken' + '=([^;]*)(;|$)')
      if (results) {
        const token = unescape(results[2])
        const decoded = jwt_decode(token)
        user.role = decoded?.role || ''
        commit('login', user)
        commit('init')
      } else {
        router.push('/login')
      }
    })
  }
}

export default actions
