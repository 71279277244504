import store from '@/store'
import { tools } from './tools'
import { constructor } from './player'
import SelectRectangle from './selector'
import { isCursorIn, isCursorInLine, isCursorInPoint, isCursorInPlane, isCursorInText, mouse, normalizeCoordsFromEvent } from './cursor'

const isEssenceIntersectRect = (essence, xl, yl, xr, yr) => {
  const leftX = essence.x - essence.offsetX
  const rightX = essence.x - essence.offsetX + essence.img.width
  const leftY = essence.y - essence.offsetY
  const rightY = essence.y - essence.offsetY + essence.img.height
  return !(leftX > xr || xl > rightX || leftY > yr || yl > rightY)
}

const pointInRectangle = (px, py, xr, yr, xl, yl) => {
  return (xl > xr ? (px >= xr && px <= xl) : (px >= xl && px <= xr)) && (yl > yr ? (py >= yr && py <= yl) : (py >= yl && py <= yr))
}

const isLinesIntersect = (line1, line2) => {
  const a = { x: line1.sx, y: line1.sy }
  const b = { x: line1.ex, y: line1.ey }
  const c = { x: line2.sx, y: line2.sy }
  const d = { x: line2.ex, y: line2.ey }

  const area = (a, b, c) => {
    return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x)
  }
  const check = (a, b, c, d) => {
    if (a > b) {
      [a, b] = [b, a]
    }
    if (c > d) {
      [c, d] = [d, c]
    }
    return Math.max(a, c) <= Math.min(b, d)
  }
  return check(a.x, b.x, c.x, d.x) &&
        check(a.y, b.y, c.y, d.y) &&
        area(a, b, c) * area(a, b, d) <= 0 &&
        area(c, d, a) * area(c, d, b) <= 0
}

const isLineIntersectRect = (line, xl, yl, xr, yr) => {
  const recLine1 = { sx: xl, ex: xr, sy: yl, ey: yl }
  const recLine2 = { sx: xr, ex: xr, sy: yl, ey: yr }
  const recLine3 = { sx: xl, ex: xr, sy: yr, ey: yr }
  const recLine4 = { sx: xl, ex: xl, sy: yl, ey: yr }
  const checkLines = (curr) => {
    return isLinesIntersect(curr, recLine1) || isLinesIntersect(curr, recLine2) ||
            isLinesIntersect(curr, recLine3) || isLinesIntersect(curr, recLine4) ||
            pointInRectangle(curr.sx, curr.sy, xr, yr, xl, yl)
  }
  if (line.points) {
    for (let entity of line.graphList) {
      const sx = line.points[entity[0]].x
      const sy = line.points[entity[0]].y
      for (let endPoint of entity[1]) {
        const ex = line.points[endPoint].x
        const ey = line.points[endPoint].y
        if (checkLines({ sx, sy, ex, ey })) {
          return true
        }
      }
    }
  } else {
    // если линии от плейнов
    return checkLines(line)
  }

  return false
}

const isTextFieldIntersectRect = (textField, xl, yl, xr, yr) => {
  const leftX = textField.x
  const rightX = textField.x + textField.width
  const leftY = textField.y
  const rightY = textField.y + textField.height + textField.yPadding
  return !(leftX > xr || xl > rightX || leftY > yr || yl > rightY)
}

const isPlaneIntersectRect = (plane, xl, yl, xr, yr) => {
  const ab = { sx: plane.a.x, sy: plane.a.y, ex: plane.b.x, ey: plane.b.y }
  const bc = { sx: plane.b.x, sy: plane.b.y, ex: plane.c.x, ey: plane.c.y }
  const dc = { sx: plane.d.x, sy: plane.d.y, ex: plane.c.x, ey: plane.c.y }
  const ad = { sx: plane.a.x, sy: plane.a.y, ex: plane.d.x, ey: plane.d.y }
  return isLineIntersectRect(ab, xl, yl, xr, yr) || isLineIntersectRect(bc, xl, yl, xr, yr) ||
        isLineIntersectRect(dc, xl, yl, xr, yr) || isLineIntersectRect(ad, xl, yl, xr, yr)
}

// координаты должны быть уже поделены на зум и с учётом translate
export const getItemsInRectangle = (x, y, width, height) => {
  let xRight, xLeft, yLeft, yRight
  if (width > 0) {
    xLeft = x
    xRight = x + width
  } else {
    xRight = x
    xLeft = x + width
  }
  if (height > 0) {
    yLeft = y
    yRight = y + height
  } else {
    yRight = y
    yLeft = y + height
  }
  const selectedAll = {
    essence: [],
    lines: [],
    planes: [],
    textFields: [],
  }
  constructor.essence.forEach((i) => {
    if (isEssenceIntersectRect(i, xLeft, yLeft, xRight, yRight)) {
      selectedAll.essence.push(i)
    }
  })
  constructor.lines.forEach((i) => {
    if (isLineIntersectRect(i, xLeft, yLeft, xRight, yRight)) {
      selectedAll.lines.push(i)
    }
  })
  constructor.textFields.forEach((i) => {
    if (isTextFieldIntersectRect(i, xLeft, yLeft, xRight, yRight)) {
      selectedAll.textFields.push(i)
    }
  })
  constructor.planes.forEach((i) => {
    if (isPlaneIntersectRect(i, xLeft, yLeft, xRight, yRight)) {
      selectedAll.planes.push(i)
    }
  })

  return selectedAll
}

const selectItemOnClick = (e) => {
  let isFound = false
  let selected = null
  if (store.getters.tools.placement) {
    const analyseItem = (type, item) => {
      isFound = true
      selected = item
      if (e.ctrlKey) {
        if (store.getters.selectedAll[type].includes(item)) {
          store.commit('removeFromSelection', { item, type })
        } else {
          store.commit('addToSelection', { item, type })
          store.dispatch('selected', item)
        }
      } else {
        store.dispatch('selected', item)
        if (!store.getters.selectedAll[type].includes(item)) {
          tools.clearSelectionObjects()
          store.commit('addToSelection', { item, type })
        }
      }
    }
    constructor.textFields.forEach((item) => {
      if (isCursorInText(item) && e.target.id === 'player') {
        analyseItem('textFields', item)
      }
    })
    constructor.essence.slice().reverse().forEach((essence) => {
      if (!isFound && isCursorIn(essence) && (e.target.id === 'player' || e.target.className === 'gifEffect')) {
        analyseItem('essence', essence)
      }
    })
    constructor.lines.forEach((item) => {
      if (!isFound && isCursorInLine(item) && e.target.id === 'player') {
        analyseItem('lines', item)
      }
    })
    constructor.planes.forEach((item) => {
      if (!isFound && isCursorInPlane(item) && e.target.id === 'player') {
        analyseItem('planes', item)
      }
    })
    if (!isFound && e.target.id === 'player' && !e.ctrlKey) {
      tools.clearSelectionObjects()
      store.commit('selected', constructor.scene)
    }
  }
  return selected
}

const selectItemOnDoubleClick = (e) => {
  const isFound = []
  if (store.getters.tools.placement) {
    if (!(e.target.id === 'player' || e.target.className === 'gifEffect')) return
    constructor.essence.slice().reverse().some((essence) => {
      if (isCursorIn(essence)) {
        isFound.push(essence)
      }
      if (isFound.length === 2) {
        tools.clearSelectionObjects()
        store.dispatch('selected', { ...essence, temp: { initialIndex: constructor.essence.indexOf(essence) } })
        tools.BringToFront(essence.id, true)
        store.commit('addToSelection', { item: essence, type: 'essence' })
        return true
      }
    })
  }
}

const moveAllSelected = (selected, offsetX, offsetY) => {
  document.querySelectorAll('.gifEffect').forEach((item) => {
    if (!selected.gifEffects) return
    selected.gifEffects.forEach((_item) => {
      if (_item.name === item.getAttribute('data-gif')) {
        _item.img.style.display = 'none'
      }
    })
  })
  const movedEssenceIds = []
  constructor.essence.forEach((item) => {
    let findItem = store.getters.selectedAll.essence.find((essenceSelect) => essenceSelect.id === item.id)
    if (findItem && item.id === findItem.id && !item.isBlocked) {
      movedEssenceIds.push(item.id)
      item.x = item.x + offsetX
      item.y = item.y + offsetY
    }
  })
  constructor.textFields.forEach((text) => {
    let findItem = store.getters.selectedAll.textFields.find((textSelect) => textSelect.id === text.id)
    if (findItem && text.id === findItem.id && !text.isBlocked) {
      text.x = text.x + offsetX
      text.y = text.y + offsetY
    }
  })
  constructor.lines.forEach((line) => {
    let findItem = store.getters.selectedAll.lines.find((lineSelect) => lineSelect.id === line.id)
    if (findItem && !line.isBlocked &&
      ((movedEssenceIds.includes(line.startID) && !line.endID) ||
        (movedEssenceIds.includes(line.endID) && !line.startID) ||
        (movedEssenceIds.includes(line.startID) && movedEssenceIds.includes(line.endID)) ||
        (!movedEssenceIds.includes(line.startID) && !movedEssenceIds.includes(line.endID))
      )
    ) {
      line.ShiftAllPoints(offsetX, offsetY)
    } else {
      if (movedEssenceIds.includes(line.startID)) {
        line.startX += offsetX
        line.startY += offsetY
      }
      if (movedEssenceIds.includes(line.endID)) {
        line.endX += offsetX
        line.endY += offsetY
      }
    }
  })
  constructor.planes.forEach((plane) => {
    let findItem = store.getters.selectedAll.planes.find((planeSelect) => planeSelect.id === plane.id)
    if (findItem && plane.id === findItem.id && !plane.isBlocked) {
      plane.a.x = plane.a.x + offsetX
      plane.a.y = plane.a.y + offsetY
      plane.b.x = plane.b.x + offsetX
      plane.b.y = plane.b.y + offsetY
      plane.c.x = plane.c.x + offsetX
      plane.c.y = plane.c.y + offsetY
      plane.d.x = plane.d.x + offsetX
      plane.d.y = plane.d.y + offsetY
      plane.x = plane.x + offsetX
      plane.y = plane.y + offsetY
    }
  })
}

const CheckSelectedPoint = (line) => {
  if (line && line.name === 'line' && !tools.editPlane) {
    for (let i of line.points) {
      if (isCursorInPoint(i)) {
        store.commit('setSelectedPoint', { point: i, line })
        return true
      }
    }
  }

  return false
}

const MergePoints = (currCoords, currPoint) => {
  const line = currPoint.line
  const inMergeArea = (toMergePoint, mergePoint, mergeArea = 5) => {
    return Math.sqrt((toMergePoint.x - mergePoint.x) * (toMergePoint.x - mergePoint.x) + (toMergePoint.y - mergePoint.y) * (toMergePoint.y - mergePoint.y)) < mergeArea
  }

  // ищем совпадение
  let foundPointIndex = null
  let foundPoint = line.points.find((point, pInd) => {
    if (inMergeArea(currCoords, point) && point !== currPoint.point) {
      foundPointIndex = pInd
      return true
    }
  })

  if (!foundPoint) {
    return
  }

  const selectedPointIndex = line.points.indexOf(currPoint.point)
  // слияние
  const toRecconnect = line.graphList.find(entity => entity[0] === selectedPointIndex)[1]
  line.graphList.forEach(entity => {
    if (toRecconnect.includes(entity[0])) {
      entity[1].splice(entity[1].indexOf(selectedPointIndex), 1)
      if (!entity[1].includes(foundPointIndex)) {
        entity[1].push(foundPointIndex)
      }
    }
  })
  const foundPointEntityInd = line.graphList.findIndex(entity => entity[0] === foundPointIndex)
  line.graphList[foundPointEntityInd][1].push(...toRecconnect)
  // убрать повторяющиеся
  const withoutClones = new Set(line.graphList[foundPointEntityInd][1])
  line.graphList[foundPointEntityInd][1] = [...withoutClones]
  line.graphList.splice(line.graphList.findIndex(entity => entity[0] === selectedPointIndex), 1)
  line.points.splice(selectedPointIndex, 1)
  line.DecrementGraphListWhenDelete(selectedPointIndex)
  // из графа удаляется путь к самой себе, если точка стала одним из концов линии
  line.graphList.forEach(entity => {
    if (entity[1].includes(entity[0])) {
      entity[1].splice(entity[1].indexOf(entity[0]), 1)
    }
  })
  // обновить анимацию
  if (line.points.length === 1) {
    constructor.lines.forEach((item) => {
      if (item.id === line.id) {
        var index = constructor.lines.indexOf(item)
        constructor.lines.splice(index, 1)
      }
    })
  }
  line.animationVisited = []
  store.commit('setSelectedPoint', { point: foundPoint, line })
}

export const SetPlacement = () => {
  // dragging of selected, selecting
  if (store.getters.tools.placement) {
    store.getters.tools.removeSelectRectangleListners = SelectRectangle.AddSelectRectangle()
    let selected
    let dragPoint = false
    let prevMoveX = 0
    let prevMoveY = 0
    let moved = false
    let moveX = 0
    let moveY = 0

    window.onmousemove = (e) => {
      if (!e.shiftKey) {
        prevMoveX = moveX
        prevMoveY = moveY
        moveX = (e.pageX) / constructor.zoom
        moveY = (e.pageY - constructor.topOffset) / constructor.zoom
        mouse.x = e.pageX
        mouse.y = e.pageY - constructor.topOffset

        // move all selected
        const offsetX = Math.round(moveX - prevMoveX)
        const offsetY = Math.round(moveY - prevMoveY)
        if (selected) {
          moveAllSelected(selected, offsetX, offsetY)
          moved = true
        }

        // перемещение точки на отрезке
        const selectedPoint = store.getters.selectedPoint
        if (selectedPoint && dragPoint && !selectedPoint.point.isBlocked) {
          selectedPoint.line.ModifyPoint(selectedPoint.point.x, selectedPoint.point.y, selectedPoint.point.x + offsetX, selectedPoint.point.y + offsetY)
        }
      }
    }

    window.onmousedown = (e) => {
      if (!e.shiftKey) {
        moveX = (e.pageX) / constructor.zoom
        moveY = (e.pageY - constructor.topOffset) / constructor.zoom
        moved = false
        if (!store.state.map.triggers.dialogShown) {
          selected = selectItemOnClick(e)
        }
        // проверяем, если курсор над точкой текущего отрезка
        if (CheckSelectedPoint(store.getters.selected)) {
          store.commit('clearSelection')
          selected = false
          dragPoint = true
        }
      }
    }

    window.ondblclick = (e) => {
      if (moved) return
      if (!e.shiftKey && !store.state.map.triggers.dialogShown) {
        selectItemOnDoubleClick(e)
      }
    }

    window.onmouseup = (e) => {
      if (!e.shiftKey) {
        moveX = 0
        moveY = 0
        prevMoveX = 0
        prevMoveY = 0
        selected = false
        normalizeCoordsFromEvent(e)
        if (dragPoint) {
          const line = store.getters.selectedPoint.line

          if (line.points.length > 2) {
            MergePoints(normalizeCoordsFromEvent(e), store.getters.selectedPoint)
          }
          dragPoint = false

          // if it's a connector without one end we can bind one to another essence
          const isConnector = (line.startID && line.startID !== true) || line.endID
          const isLoneConnector = (!line.startID || !line.endID) && isConnector

          if (!isLoneConnector) return

          constructor.essence.forEach((essence) => {
            if (
              isLoneConnector &&
              isCursorIn(essence) &&
              (e.target.id === 'player' || e.target.className === 'gifEffect') &&
              essence.id !== line.startID &&
              essence.id !== line.endID
            ) {
              line.startID = line.startID || essence.id
              line.endID = line.endID || essence.id
            }
            if (line.startID === essence.id) {
              line.startX = essence.x + essence.centerPointOffsetX
              line.startY = essence.y + essence.centerPointOffsetY
            }
            if (line.endID === essence.id) {
              line.endX = essence.x + essence.centerPointOffsetX
              line.endY = essence.y + essence.centerPointOffsetY
            }
          })
        } else if (!e.target.closest('#customMenu')) {
          store.commit('setSelectedPoint', null)
        }
      }
    }
  }
}
