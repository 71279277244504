<template>
  <div class="cbr-carousel">
    <div
      v-if="!props.hideArrows"
      class="cbr-carousel__button cbr-carousel__button--left"
      :class="{
        'cbr-carousel__button--disabled': isLeftButtonDisabled
      }"
      @click="onLeftButtonClick"
    >
      <CbrIcon>$cbrArrowLeft</CbrIcon>
    </div>
    <div
      ref="itemsContainerTemplateRef"
      class="cbr-carousel__items-container"
      :class="{
        'cbr-carousel__items-container--full': visibleWidth === scrollWidth
      }"
    >
      <div
        v-for="(item, itemIndex) in props.items"
        :key="itemIndex"
        class="cbr-carousel__item-wrapper"
        :style="getItemStyle"
      >
        <slot name="item" :item="item"/>
      </div>
    </div>

    <div
      v-if="!props.hideArrows"
      class="cbr-carousel__button cbr-carousel__button--right"
      :class="{
        'cbr-carousel__button--disabled': isRightButtonDisabled
      }"
      @click="onRightButtonClick"
    >
      <CbrIcon>$cbrArrowRight</CbrIcon>
    </div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onBeforeUnmount, onMounted, onUpdated, ref } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  itemWidth: {
    type: Number,
    required: true
  },
  hideArrows: {
    type: Boolean,
    default: true
  }
})

const ITEMS_GAP_SIZE = 28
const stepSize = computed(() => {
  return ITEMS_GAP_SIZE + props.itemWidth
})

const translateX = ref(0)
const scrollWidth = ref(null)
const visibleWidth = ref(null)

const getItemStyle = computed(() => {
  return {
    transform: `translateX(${translateX.value}px)`,
  }
})
function onLeftButtonClick () {
  if (isLeftButtonDisabled.value) {
    return
  }

  const translationToAdd = Math.min(Math.abs(translateX.value), stepSize.value)
  translateX.value += translationToAdd
}
function onRightButtonClick () {
  if (isRightButtonDisabled.value) {
    return
  }

  const scrollWidthDifference = scrollWidth.value - visibleWidth.value - Math.abs(translateX.value)
  const translationToAdd = Math.min(scrollWidthDifference, stepSize.value)
  translateX.value -= translationToAdd
}

onMounted(() => {
  onResize()
  window.addEventListener('resize', onResize)
})
onUpdated(() => {
  onResize()
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})
const vueCurrentInstance = getCurrentInstance().proxy
function onResize () {
  const newScrollWidth = props.itemWidth * props.items.length + ITEMS_GAP_SIZE * (props.items.length - 1)
  const newVisibleWidth = vueCurrentInstance.$refs.itemsContainerTemplateRef.offsetWidth

  const visibleWidthDifference = newVisibleWidth - visibleWidth.value
  if (visibleWidthDifference > 0) {
    translateX.value += Math.min(visibleWidthDifference, Math.abs(translateX.value))
  }

  scrollWidth.value = newScrollWidth
  visibleWidth.value = newVisibleWidth
}

const isLeftButtonDisabled = computed(() => {
  return scrollWidth.value === visibleWidth.value || translateX.value === 0
})
const isRightButtonDisabled = computed(() => {
  return scrollWidth.value === visibleWidth.value || Math.abs(translateX.value) + visibleWidth.value === scrollWidth.value
})
</script>

<style scoped lang="scss">
.cbr-carousel {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 48px;

  &__items-container {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 28px;
    overflow-x: hidden;
    &--full {
      justify-content: center;
    }
  }

  &__item-wrapper {
    transition: all .4s ease-out;
  }
  &__item {
    height: 400px;
    width: 300px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 32px;
    height: 64px;
    border: 1px solid $ActionColor;
    background-color: rgba($ActionColor, .15);
    &--right {
      padding-right: 4px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &--left {
      padding-left: 4px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &--disabled {
      cursor: default;
      opacity: .3;
      * {
        cursor: default !important;
      }
    }
  }
}
</style>
