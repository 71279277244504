<template>
  <div>
    <CbrDataTable
      class="user-events-table cbr-popup-table"
      :items="events"
      :headers="headers"
      :no-data-text="$t('user_card_hdr_no_records')"
      disable-pagination
      disable-sort
      height="230px"
    >
      <template v-slot:item.name="{ item }">
        <CbrHint :description="item.name" bottom no-icon>
          <div class="user-events-event-name" :class="{ w500: isUser }">
              <CbrIcon class="mb-1">$calendar</CbrIcon>
              <a :href="`/event/${item.gameType.toLowerCase()}/${item.eventId}`">{{ item.name }}</a>
            </div>
        </CbrHint>
      </template>
      <template v-slot:item.mission="{ item }">
        <CbrHint :description="item.missionName" bottom no-icon>
          <div class="user-events-event-mission">
            <span>{{ item.missionName }}</span>
          </div>
        </CbrHint>
      </template>
      <template v-slot:item.startDate="{ item }">
        <div class="user-events-date-start">
          {{ formatDate(item.startTimestamp) }}
        </div>
      </template>
    </CbrDataTable>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@/store'
import moment from 'moment'
import { i18n } from '@/i18n-setup'

const store = useStore()
defineProps({
  events: {
    type: Array,
    required: true
  }
})

const isUser = computed(() => store.getters['account/isUser'])
const headers = computed(() => [
  {
    text: i18n.t('org_panel_popup_unfinish_event_hdr_column_event'),
    value: 'name'
  }, {
    text: i18n.t('user_card_hdr_column_mission'),
    value: 'mission'
  }, {
    text: i18n.t('user_card_hdr_column_start_date'),
    value: 'startDate'
  }
])

const formatDate = (timestamp) => timestamp ? moment(timestamp).format('DD.MM.YYYY') : null

</script>

<style lang="scss" scoped>
.user-events-table {
  border: none;
  padding: 10px 10px 0 !important;
  ::v-deep table {
    border-spacing: 0 6px !important;
    thead > tr > th {
      height: 28px !important;
    }
  }
}

.user-events-event-name, .user-events-event-mission {
  width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;

  a {
    color: $base-color;
  }
  .v-icon {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    ::v-deep path {
      fill: $base-color;
    }
  }
}
.w500 {
  max-width: 600px !important;
}
.user-events-date-start {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
}
::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr>th:last-child {
  width: 150px;
}
</style>
