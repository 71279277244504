var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cbr-carousel"},[(!_setup.props.hideArrows)?_c('div',{staticClass:"cbr-carousel__button cbr-carousel__button--left",class:{
      'cbr-carousel__button--disabled': _setup.isLeftButtonDisabled
    },on:{"click":_setup.onLeftButtonClick}},[_c('CbrIcon',[_vm._v("$cbrArrowLeft")])],1):_vm._e(),_c('div',{ref:"itemsContainerTemplateRef",staticClass:"cbr-carousel__items-container",class:{
      'cbr-carousel__items-container--full': _setup.visibleWidth === _setup.scrollWidth
    }},_vm._l((_setup.props.items),function(item,itemIndex){return _c('div',{key:itemIndex,staticClass:"cbr-carousel__item-wrapper",style:(_setup.getItemStyle)},[_vm._t("item",null,{"item":item})],2)}),0),(!_setup.props.hideArrows)?_c('div',{staticClass:"cbr-carousel__button cbr-carousel__button--right",class:{
      'cbr-carousel__button--disabled': _setup.isRightButtonDisabled
    },on:{"click":_setup.onRightButtonClick}},[_c('CbrIcon',[_vm._v("$cbrArrowRight")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }