<template>
  <div id="tools-panel" class="tools-panel" :class="isTopPanelFixed ? 'locked' : null" @click="onClickTools">
    <div class="tools-panel_wrapper">
      <div class="tools-menu" v-if="menu">
        <div class="tools-menu_wrapper">
          <div class="tools-menu_block">
            <button class="tools-menu_item" @click="newConfig()">
              <CbrIcon>$toolsMenuNewConfig</CbrIcon>
              {{$t('main_menu_btn_new_config')}}
            </button>
            <a id="load" class="tools-menu_item" @click="$refs.configInput.click()">
              <CbrIcon>$toolsMenuOpenConfig</CbrIcon>
              {{$t('main_menu_btn_open_config')}}
            </a
            >
            <input
              id="loadconfig"
              type="file"
              style="display: none"
              @click="loadConfig"
              ref="configInput"
            />
            <a id="append" class="tools-menu_item" @click="$refs.addConfigInput.click()">
              <CbrIcon>$toolsMenuAppendConfig</CbrIcon>
              {{$t('main_menu_btn_append_from_file')}}
            </a
            >
            <input
              id="appendConfig"
              type="file"
              style="display: none"
              @click="appendConfig"
              ref="addConfigInput"
            />
            <a id="download" class="tools-menu_item" @click="uploadConfig">
              <CbrIcon>$toolsMenuSaveConfig</CbrIcon>
              {{$t('main_menu_btn_download_config')}}
            </a>
            <template v-if="!contractedBuild">
              <button class="tools-menu_item" @click="addComp">
                <CbrIcon>$toolsMenuAddComponent</CbrIcon>
                {{$t('main_menu_btn_add_new_component_to_library')}}
              </button>
              <button class="tools-menu_item" @click="addIcon">
                <CbrIcon>$toolsMenuAddIcon</CbrIcon>
                {{$t('main_menu_btn_add_new_icon_library')}}
              </button>
              <button class="tools-menu_item" @click="addGifEffect">
                <CbrIcon>$toolsMenuAddGif</CbrIcon>
                {{$t('main_menu_btn_add_new_gif_effect_to_library')}}
              </button>
            </template>
          </div>
        </div>
      </div>
      <div
        id="menu"
        class="tools-panel_button"
        :class="{ 'tools-panel_button--active': menu }"
        @click="openMenu"
      >
        <CbrIcon large>$toolsMenu</CbrIcon>
      </div>
      <CbrHint :description="$t('panel_objects_btn_move_object')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="cursor_move"
          @click="placement"
          :class="{ 'tools-panel_button--active': tools.placement }"
        >
          <CbrIcon large>$toolsCursor</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint :description="$t('panel_objects_btn_make_line')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="line"
          @click="addLine"
          :class="{ 'tools-panel_button--active': tools.addLine }"
        >
          <CbrIcon large>$toolsLine</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint :description="$t('panel_objects_btn_connect')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="connect"
          @click="connect"
          :class="{ 'tools-panel_button--active': tools.connection }"
        >
          <CbrIcon large>$toolsConnect</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint :description="$t('panel_objects_btn_add_point_on_line')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="addlinepoint"
          @click="addPointInLine"
          :class="{ 'tools-panel_button--active': tools.addPointInLine }"
        >
          <CbrIcon large v-if="isActiveAddPointOnLine">$toolsAddlinepoint</CbrIcon>
          <CbrIcon large v-else>$toolsAddlinepointGray</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint :description="$t('panel_objects_btn_make_plane')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="plane"
          @click="makePlane"
          :class="{ 'tools-panel_button--active': tools.makePlane }"
        >
          <CbrIcon large>$toolsPlane</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint :description="$t('panel_objects_btn_edit_plane')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="plane"
          @click="editPlane"
          :class="{ 'tools-panel_button--active': tools.editPlane }"
        >
          <CbrIcon large>$toolsEditplane</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint :description="$t('panel_objects_btn_make_text')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="text"
          @click="makeText"
          :class="{ 'tools-panel_button--active': tools.makeText }"
        >
          <CbrIcon large>$toolsText</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint :description="$t('panel_objects_btn_ScreenShot')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="screenshot"
          @click="screenShot()"
        >
          <CbrIcon large>$toolsCamera</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint :description="$t('panel_objects_btn_move_icon')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="text"
          @click="moveIcon"
          :class="{ 'tools-panel_button--active': tools.moveIcon }"
        >
          <CbrIcon large>$toolsIcon</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint :description="$t('panel_objects_btn_change_base_object')" bottom no-icon>
        <div
          class="tools-panel_button"
          id="text"
          @click="switchBaseObj"
          :class="{ 'tools-panel_button--active': baseObj }"
        >
          <CbrIcon large>$toolsBaseobj</CbrIcon>
        </div>
      </CbrHint>
      <CbrHint
        :description="$t(isTopPanelFixed ? 'panel_objects_btn_unlock_panel' : 'panel_objects_btn_lock_panel')"
        bottom
        no-icon
      >
        <div
          class="tools-panel_button tools-panel_button-lock"
          id="lock"
          @click="lockPanel"
        >
          <CbrSwitch :value="isTopPanelFixed" icon-on="mdi-pin-outline" icon-off="mdi-pin-off-outline" fade />
        </div>
      </CbrHint>
    </div>
    <CbrDialog
      v-model="doubleDialog"
      :title="$t('error_load_lbl_error_text')"
      error
    >
      <template #content>
        <span>{{$t('error_load_lbl_Duplicated_IDs')}}:</span>
        <div>
          <div v-for="id in doubleIds" :key="id">{{ id }}</div>
        </div>
      </template>
      <template #footer>
        <CbrButton
          :text="$t('trigger_text_btn_close')"
          @click="doubleDialog = false"
        />
      </template>
    </CbrDialog>
    <CbrDialog
      v-model="confirmCloseDialog"
      :title="$t('create_config_hdr_create_config')"
      :first-line-text="$t('create_config_lbl_unsaved_files')"
      max-width="668px"
    >
      <template #footer>
        <CbrButton
          :text="$t('create_config_btn_clear')"
          @click="onConfirmClose"
          error
        />
        <CbrButton
          :text="$t('create_config_btn_cancel')"
          @click="confirmCloseDialog = false"
          border
        />
      </template>
    </CbrDialog>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from '@/store'
import { constructor } from '@/plugins/map/constructor/player'
import { config } from '@/plugins/map/constructor/map-config'

const store = useStore()
const emit = defineEmits(['addComp', 'addIcon', 'addGifEffect'])

const isTopPanelFixed = ref(false)
const doubleDialog = ref(false)
const confirmCloseDialog = ref(false)
const contractedBuild = process.env.VUE_APP_BUILD_CONTRACTED === 'true'

isTopPanelFixed.value = JSON.parse(localStorage.getItem('tools-config'))?.isTopPanelFixed || false

const tools = computed(() => store.getters.tools)
const menu = computed(() => store.getters.tools.menu)
const selected = computed(() => store.getters.selected)
const baseObj = computed(() => store.getters.switchBaseObj)
const doubleIds = computed(() => store.getters.doubleIds)
const countOfSelected = computed(() => store.getters.countOfSelected)
const isActiveAddPointOnLine = computed(() => {
  return selected.value.name === 'line' && !selected.value.isBlocked && countOfSelected.value === 1
})

const lockPanel = () => {
  isTopPanelFixed.value = !isTopPanelFixed.value
  localStorage.setItem(
    'tools-config',
    JSON.stringify(
      Object.assign(JSON.parse(localStorage.getItem('tools-config')) || {}, {
        isTopPanelFixed: isTopPanelFixed.value
      })
    )
  )
}
const openMenu = () => {
  if (
    constructor.essence.length ||
    constructor.planes.length ||
    constructor.lines.length ||
    constructor.textFields.length
  ) {
    store.commit('setIsConfigOpened', true)
  }
  store.commit('switchToolsMenu', !menu.value)
}
const onClickTools = (e) => {
  if (e.target.id === 'tools-panel') {
    closeMenu()
  }
}
const closeMenu = () => {
  store.commit('switchToolsMenu', false)
}
const newConfig = () => {
  confirmCloseDialog.value = true
  closeMenu()
}
const onConfirmClose = () => {
  localStorage.removeItem('savedConfig')
  constructor.Refresh()
  store.commit('setSwitchBaseObj', false)
  confirmCloseDialog.value = false
}
const appendConfig = () => {
  tools.value.AppendConfig()
  store.commit('setSwitchBaseObj', false)
  closeMenu()
}
const placement = () => tools.value.Placement()
const connect = () => tools.value.Connect()
const addLine = () => tools.value.AddLine()
const makePlane = (e) => tools.value.MakePlane(e)
const editPlane = () => tools.value.EditPlane()
const makeText = (e) => tools.value.MakeTextField(e)
const addPointInLine = () => {
  if (!isActiveAddPointOnLine.value) return
  tools.value.AddPointInLine()
}
const uploadConfig = () => {
  tools.value.UploadConfig()
  closeMenu()
}
const loadConfig = () => {
  tools.value.LoadConfig()
  store.commit('setSwitchBaseObj', false)
  closeMenu()
}
const moveIcon = () => {
  tools.value.MoveIcon(constructor, selected.value)
}
const addComp = () => {
  emit('addComp', { name: 'addComp', isOpen: true })
  closeMenu()
}
const addIcon = () => {
  emit('addIcon', { name: 'addIcon', isOpen: true })
  closeMenu()
}
const addGifEffect = () => {
  emit('addGifEffect')
  closeMenu()
}
const switchBaseObj = () => {
  store.commit('setSwitchBaseObj', !baseObj.value)
  if (baseObj.value) {
    config.SetDefaultConfig()
  }
}
const screenShot = () => constructor.ScreenShot()
const hideMenu = (e) => {
  if (e.target.id === 'player') {
    closeMenu()
  }
}

watch(() => doubleIds.value, () => {
  doubleDialog.value = !!doubleIds.value.length
})

onMounted(() => document.addEventListener('click', hideMenu))
onBeforeUnmount(() => document.removeEventListener('click', hideMenu))
</script>

<style lang="scss" scoped>
.tools-panel {
  position: absolute;
  top: -25px;
  display: flex;
  justify-content: center;
  width: calc(100vw - 2px);
  z-index: 98;
  &:hover {
    top: 0;
    transition: all ease 0.3s;
  }
  &.locked {
    top: 0 ;
    transition: all ease 0.3s;
  }
  &_wrapper {
    display: flex;
    justify-content: center;
    width: fit-content;
    background: rgba($base-color-light, 0.05);
    backdrop-filter: blur(12.5px);
  }
  &_button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 48px;
    height: 48px;
    &--active {
      background-color: $base-color !important;
      ::v-deep svg {
        path, line, circle {
          fill: $base-color-dark;
          stroke: $base-color-dark !important;
        }
        rect {
          stroke: $base-color-dark !important;
        }
      }
    }
    &:hover {
      background: rgba($base-color, 0.08);
    }
    &-lock {
      border-left: 1px solid rgba($base-color-light, 0.1);
      width: 84px;
    }
  }
  .tools-menu {
    position: absolute;
    top: 48px;
    left: 0px;
    box-shadow: 0px 4px 4px rgba($base-black, 0.25);
    &_wrapper {
      width: 400px;
      background: $base-color-dark;
      @include cut-corners(0px, false, 11px, false);
      place-content: normal;
      padding: 0;
    }
    &_block {
      background: rgba($base-color, 0.05);
      @include cut-corners(0px, false, 11px, false, 1px, rgba($base-color, 0.25));
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &_item {
      margin: 0;
      font-size: 16px;
      background: none;
      border-radius: 0;
      color: $base-color;
      padding: 0 0 0 16px;
      height: 36px;
      display: flex;
      align-items: center;
      gap: 6px;
      &:hover {
        background: rgba($base-color, 0.08);
      }
    }
  }
}
</style>
