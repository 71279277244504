export const mapRu = {
  panel_objects_hdr_library: 'Библиотека',
  panel_objects_hdr_essence: 'Объект',
  panel_objects_hdr_line: 'Линия',
  panel_objects_hdr_plane: 'Подложка',
  panel_objects_hdr_scene: 'Сцена',
  panel_objects_hdr_text: 'Текст',
  panel_objects_btn_object: 'Объекты',
  panel_objects_btn_icons: 'Иконки',
  panel_objects_edit_search: 'Поиск',
  panel_objects_btn_lock_panel: 'Закрепить панель',
  panel_objects_btn_unlock_panel: 'Открепить панель',
  panel_objects_btn_move_object: 'Переместить объект',
  panel_objects_btn_make_line: 'Создание линии',
  panel_objects_btn_connect: 'Соединить',
  panel_objects_btn_add_point_on_line: 'Добавить точку на линию',
  panel_objects_btn_make_plane: 'Создание подложки',
  panel_objects_btn_edit_plane: 'Редактировать подложку',
  panel_objects_btn_make_text: 'Добавить текст',
  panel_objects_btn_change_base_object: 'Изменить основной объект',
  panel_objects_btn_move_icon: 'Переместить икону',
  panel_objects_btn_ScreenShot: 'Скриншот',
  properties_panel_visualization_edit_notification: 'Уведомление',
  properties_panel_properties_scene_lbl_color_header: 'Цвет',
  properties_panel_properties_scene_lbl_effects: 'Эффекты',
  properties_panel_properties_scene_btn_lose: 'Проигрыш',
  properties_panel_properties_scene_btn_progressbar_red: 'Красный индикатор',
  properties_panel_properties_scene_btn_progressbar_yellow: 'Жёлтый индикатор',
  main_menu_btn_new_config: 'Новая сцена',
  main_menu_btn_open_config: 'Открыть сцену',
  main_menu_btn_append_from_file: 'Импорт из файла',
  main_menu_btn_download_config: 'Сохранить сцену',
  main_menu_btn_add_new_component_to_library: 'Добавить новый объект в библиотеку',
  main_menu_btn_add_new_icon_library: 'Добавить новую иконку в библиотеку',
  main_menu_btn_add_new_gif_effect_to_library: 'Добавить новый gif эффект в библиотеку',
  add_gif_lbl_add_gif: 'Добавление gif-эффекта',
  add_gif_hdr_add_gif: 'Выберите объект для которого хотите добавить gif-эффект',
  add_gif_edit_search_object: 'Поиск',
  add_gif_edit_name_effect: 'Название эффекта',
  add_gif_edit_name_effect_plh: 'Введите название эффекта',
  add_gif_chk_hide_component: 'Скрыть компонент',
  add_gif_btn_choose_gif: 'Открыть gif',
  add_gif_lbl_offset: 'Смещение эффекта по осям:',
  add_gif_spinneredit_offset_x: 'Смещение X',
  add_gif_spinneredit_offset_y: 'Смещение Y',
  add_gif_file_incorrect_format: 'Некорректный формат файла.\nДопустимые форматы: *.gif',
  add_gif_btn_save: 'Сохранить',
  add_gif_btn_clear: 'Очистить',
  add_image_hdr_add_image: 'Добавление изображения',
  add_image_edit_input_name_image: 'Название иконки',
  add_image_edit_input_name_image_plh: 'Введите название иконки',
  add_image_edit_input_name_image_empty: 'Не заполнено обязательное поле',
  add_image_file_incorrect_format: 'Некорректный формат файла.\nДопустимые форматы: *.svg, *.png, *.jpeg',
  add_object_hdr_add_add_object: 'Добавление объекта в библиотеку',
  add_object_edit_name_object: 'Название объекта',
  add_object_edit_name_object_plh: 'Введите название объекта',
  add_object_edit_name_object_empty: 'Не заполнено обязательное поле',
  add_object_file_incorrect_format: 'Некорректный формат файла.\nДопустимые форматы: *.png, *.jpeg',
  add_object_btn_upload: 'Загрузить файл',
  add_object_btn_reload: 'Обновить файл',
  add_object_btn_add: 'Добавить',
  add_object_btn_cancel: 'Отменить',
  add_object_btn_close: 'Закрыть',
  add_object_lbl_same_size: 'Все изображения должны быть одинакового размера',
  add_object_lbl_no_file_loaded: 'Файл не загружен!',
  add_object_hdr_upload_error: 'Ошибка загрузки',
  grid_edit_grid: 'Сетка',
  context_menu_lst_block: 'Заблокировать',
  context_menu_lst_unblock: 'Разблокировать',
  context_menu_lst_delete: 'Удалить',
  context_menu_lst_copy_triggers: 'Копировать триггеры',
  context_menu_lst_past_triggers: 'Вставить триггеры',
  properties_panel_object_toggler_panel_pinned: 'Панель закреплена',
  properties_panel_object_toggler_panel_unpinned: 'Панель откреплена',
  properties_panel_object_btn_blocked: 'Разблокировать',
  properties_panel_object_btn_unblocked: 'Заблокировать',
  properties_panel_object_btn_set_trigges: 'Установить триггеры',
  properties_panel_object_btn_bring_to_front: 'Перенести на передний план',
  properties_panel_object_btn_bring_to_back: 'Перенести на задний план',
  properties_panel_object_lbl_default_options: 'Параметры по умолчанию',
  properties_panel_object_edit_label: 'Название',
  properties_panel_object_lbl_state: 'Состояние',
  properties_panel_object_btn_state_off: 'Состояние выключено',
  properties_panel_object_btn_state_on: 'Состояние включено',
  properties_panel_object_btn_state_warning: 'Состояние предупреждения',
  properties_panel_object_btn_state_critical: 'Критическое состояние',
  properties_panel_object_lbl_effects: 'Эффекты',
  properties_panel_object_btn_attack_down: 'Атака отражена',
  properties_panel_object_btn_progressbar_yellow: 'Жёлтый индикатор',
  properties_panel_object_btn_progressbar_red: 'Красный индикатор',
  properties_panel_object_btn_network_scanning: 'Сканирование сети',
  properties_panel_object_btn_vulnerabilities_scanning: 'Сканирование на уязвимости',
  properties_panel_object_btn_user_settings: 'Пользовательские настройки',
  properties_panel_object_btn_warning_network_scan: 'Сетевое сканирование',
  properties_panel_object_btn_addtitional_scan_blue: 'Дополнительное сканирование голубая линия',
  properties_panel_object_btn_critical_succesful_attack: 'Успешная критическая атака',
  properties_panel_object_btn_addtitional_scan_green: 'Дополнительное сканирование бирюзовая линия',
  properties_panel_object_lbl_gif_effects: 'Gif эффект',
  properties_panel_object_lbl_icons: 'Контекстные иконки',
  properties_panel_object_btn_delete_icon: 'Удалить',
  properties_panel_object_lbl_change_center: 'Изменение центра',
  properties_panel_object_btn_change_center: 'Изменить центр',
  properties_panel_object_btn_delete_object: 'Удалить',
  properties_panel_object_edit_rotate_angle: 'Угол поворота текста',
  properties_panel_object_edit_skew_angle: 'Угол скоса текста',
  properties_panel_object_lbl_info_properties: 'Для отображения свойств выберите только один объект',
  properties_panel_object_lbl_color_properties: 'Цвет',
  properties_panel_object_lbl_font_size: 'Размер шрифта',
  properties_panel_object_lbl_line_properties: 'Свойства линии',
  properties_panel_object_edit_start: 'Начало',
  properties_panel_object_edit_end: 'Конец',
  properties_panel_object_edit_start_x: 'Начало линии x',
  properties_panel_object_edit_start_y: 'Начало линии y',
  properties_panel_object_edit_end_x: 'Конец линии x',
  properties_panel_object_edit_end_y: 'Конец линии y',
  properties_panel_object_edit_line_width: 'Ширина линии',
  properties_panel_object_btn_animate_direction: 'Направление анимации',
  properties_panel_object_btn_animate_direction_reverse: 'В обратном направлении',
  test_animation_hdr_test: 'Тестирование анимации',
  test_animation_btn_default: 'По умолчанию',
  test_animation_btn_update_scene: 'Обновить сцену',
  test_animation_btn_add_base_item: 'Добавить конфигурацию',
  test_animation_btn_reset_scene: 'Сбросить сцену',
  test_animation_btn_base: 'Конфигурация',
  trigger_object_lbl_set_triggers: 'Установка триггеров',
  trigger_object_lbl_state: 'Состояние',
  trigger_object_lst_state_on: 'Включен',
  trigger_object_lst_state_off: 'Выключен',
  trigger_object_lst_state_warning: 'Предупреждение',
  trigger_object_lst_state_critical: 'Критический',
  trigger_object_lbl_icon: 'Иконка',
  trigger_object_lbl_effects: 'Эффекты',
  trigger_object_lbl_attack_down: 'Атака отражена',
  trigger_object_lbl_progressbar_yellow: 'Жёлтый индикатор',
  trigger_object_lbl_progressbar_red: 'Красный индикатор',
  trigger_object_lbl_network_scanning: 'Сетевое сканирование',
  trigger_object_lbl_vunerabilities_scanning: 'Сканирование на уязвимости',
  trigger_object_lbl_gif_effects: 'gif эффекты',
  trigger_object_lbl_name: 'Название',
  trigger_object_lbl_no_more_than_64_characters: 'Не более 64 символов',
  trigger_object_lbl_condition: 'Условие',
  trigger_object_lbl_no_more_than_1024_characters: 'Не более 1024 символов',
  trigger_object_lbl_incorrect_format: 'Неверный формат',
  trigger_object_lbl_required_field: 'Не заполнено обязательное поле',
  trigger_object_lbl_no_triggers: 'Триггеры не установлены',
  trigger_object_btn_new: 'Новый',
  trigger_object_btn_delete: 'Удалить',
  trigger_object_hdr_name_column: 'Название',
  trigger_object_hdr_state_column: 'Состояние',
  trigger_object_hdr_effects_column: 'Эффекты',
  trigger_object_hdr_gif_effects_column: 'Gif эффекты',
  trigger_object_hdr_icon_column: 'Иконка',
  trigger_object_btn_save: 'Сохранить',
  trigger_object_btn_set_triggers: 'Установить триггеры',
  trigger_object_btn_copy: 'Копировать',
  trigger_object_btn_paste: 'Вставить',
  trigger_object_btn_duplicate: 'Дублировать',
  trigger_line_lbl_set_triggers: 'Установка триггеров',
  trigger_line_lbl_effects: 'Эффекты',
  trigger_line_lbl_AdditionalScanBlue: 'Дополнительное сканирование голубая линия',
  trigger_line_lbl_AdditionalScanGreen: 'Дополнительное сканирование бирюзовая линия',
  trigger_line_lbl_CriticalSuccessfulAttack: 'Успешная критическая атака',
  trigger_line_lbl_UserSettings: 'Пользовательские настройки',
  trigger_line_lbl_WarningNetworkScan: 'Сетевое сканирование',
  trigger_line_lbl_width: 'Ширина',
  trigger_line_lbl_required_field_width: 'Обязательное поле',
  trigger_line_lbl_color: 'Цвет',
  trigger_line_lbl_name: 'Название',
  trigger_line_lbl_no_more_than_64_characters: 'Не более 64 символов',
  trigger_line_lbl_condition: 'Условие',
  trigger_line_lbl_no_more_than_1024_characters: 'Не более 1024 символов',
  trigger_line_lbl_required_field: 'Обязательное поле',
  trigger_line_lbl_incorrect_format: 'Неверный формат',
  trigger_line_btn_new: 'Новый',
  trigger_line_btn_delete: 'Удалить',
  trigger_line_hdr_name: 'Название',
  trigger_line_hdr_effects: 'Эффекты',
  trigger_line_hdr_width: 'Ширина',
  trigger_line_hdr_color: 'Цвет',
  trigger_line_btn_save: 'Сохранить',
  trigger_line_btn_set_triggers: 'Установить триггеры',
  trigger_line_btn_copy: 'Копировать',
  trigger_line_btn_paste: 'Вставить',
  trigger_line_btn_duplicate: 'Дублировать',
  trigger_line_btn_close: 'Закрыть',
  trigger_scene_lbl_set_triggers: 'Установка триггеров',
  trigger_scene_lbl_effects: 'Эффекты',
  trigger_scene_lbl_lose: 'Проигрыш',
  trigger_scene_lbl_progressbaryellow: 'Жёлтый индикатор',
  trigger_scene_lbl_progressbarred: 'Красный индикатор',
  trigger_scene_lbl_notification: 'Уведомление',
  trigger_scene_lbl_no_more_than_128_characters: 'Не более 128 символов',
  trigger_scene_lbl_notification_color: 'Цвет уведомления',
  trigger_scene_lbl_name: 'Название',
  trigger_scene_lbl_no_more_than_64_characters: 'Не более 64 символов',
  trigger_scene_lbl_condition: 'Условие',
  trigger_scene_lbl_no_more_than_1024_characters: 'Не более 1024 символов',
  trigger_scene_lbl_required_field: 'Обязательное поле',
  trigger_scene_lbl_incorrect_format: 'Неверный формат',
  trigger_scene_btn_new: 'Новый',
  trigger_scene_btn_delete: 'Удалить',
  trigger_scene_hdr_name: 'Название',
  trigger_scene_hdr_effects: 'Эффекты',
  trigger_scene_hdr_notification: 'Уведомление',
  trigger_scene_hdr_color: 'Цвет уведомления',
  trigger_scene_btn_save: 'Сохранить',
  trigger_scene_btn_set_triggers: 'Установить триггеры',
  trigger_scene_btn_copy: 'Копировать',
  trigger_scene_btn_paste: 'Вставить',
  trigger_scene_btn_duplicate: 'Дублировать',
  trigger_scene_btn_close: 'Закрыть',
  trigger_background_lbl_set_triggers: 'Установка триггеров',
  trigger_background_lbl_background_color: 'Цвет фона',
  trigger_background_lbl_stroke_color: 'Цвет обводки',
  trigger_background_lbl_name: 'Название',
  trigger_background_lbl_no_more_than_64_characters: 'Не более 64 символов',
  trigger_background_lbl_condition: 'Условие',
  trigger_background_lbl_no_more_than_1024_characters: 'Не более 1024 символов',
  trigger_background_lbl_required_field: 'Обязательное поле',
  trigger_background_lbl_incorrect_format: 'Неверный формат',
  trigger_background_btn_new: 'Новый',
  trigger_background_btn_delete: 'Удалить',
  trigger_background_hdr_name: 'Название',
  trigger_background_hdr_background_color: 'Цвет фона',
  trigger_background_hdr_stroke_color: 'Цвет обводки',
  trigger_background_btn_save: 'Сохранить',
  trigger_background_btn_set_triggers: 'Установить триггеры',
  trigger_background_btn_copy: 'Копировать',
  trigger_background_btn_paste: 'Вставить',
  trigger_background_btn_duplicate: 'Дублировать',
  trigger_background_btn_close: 'Закрыть',
  trigger_text_lbl_set_triggers: 'Установка триггеров',
  trigger_text_lbl_text: 'Текст',
  trigger_text_lbl_no_more_than_128_characters: 'Не более 128 символов',
  trigger_text_lbl_required_field_text: 'Обязательное поле',
  trigger_text_lbl_size: 'Размер шрифта',
  trigger_text_lbl_size_required_field_size: 'Обязательное поле',
  trigger_text_lbl_color: 'Цвет текста',
  trigger_text_lbl_name: 'Название',
  trigger_text_lbl_no_more_than_64_characters: 'Не более 64 символов',
  trigger_text_lbl_condition: 'Условие',
  trigger_text_lbl_no_more_than_1024_characters: 'Не более 1024 символов',
  trigger_text_lbl_required_field: 'Обязательное поле',
  trigger_text_lbl_incorrect_format: 'Неверный формат',
  trigger_text_btn_new: 'Новый',
  trigger_text_btn_delete: 'Удалить',
  trigger_text_hdr_name: 'Название',
  trigger_text_hdr_text: 'Текст',
  trigger_text_hdr_size: 'Размер',
  trigger_text_hdr_color: 'Цвет',
  trigger_text_btn_save: 'Сохранить',
  trigger_text_btn_set_triggers: 'Установить триггеры',
  trigger_text_btn_copy: 'Копировать',
  trigger_text_btn_paste: 'Вставить',
  trigger_text_btn_duplicate: 'Дублировать',
  trigger_text_btn_close: 'Закрыть',
  trigger_notification_save_hdr_set_triggers: 'Установка триггеров',
  trigger_notification_save_lbl_text: 'Сохранить изменения?',
  trigger_notification_save_btn_save: 'Сохранить',
  trigger_notification_save_btn_don_t_save: 'Не сохранять',
  trigger_notification_save_btn_cancel: 'Отмена',
  trigger_notification_delete_hdr_set_triggers: 'Установка триггеров',
  trigger_notification_delete_lbl_text: 'Удалить элемент?',
  trigger_notification_delete_btn_delete: 'Удалить',
  trigger_notification_delete_btn_cancel: 'Отмена',
  trigger_notification_error_hdr_set_triggers: 'Следующие триггеры содержат ошибки:',
  trigger_notification_error_lbl_text: 'Ошибка в триггерах',
  trigger_notification_error_btn_close: 'Закрыть',
  properties_panel_properties_scene_edit_message: 'Сообщение',
  add_object_lbl_no_image: 'Нет изображения',
  panel_objects_lbl_nothing_found: 'Ничего не найдено',
  error_load_lbl_error_text: 'Ошибка при открытии сцены',
  error_load_lbl_Duplicated_IDs: 'Дублирование ID',
  add_gif_lbl_saved: 'Сохранено',
  trigger_notification_delete_lbl_text_items: 'Удалить элементы?',
  trigger_text_btn_set_trigger: 'Установить триггер',
  test_animation_lbl_modifyng_object: 'Изменение объекта',
  properties_panel_object_lbl_test_mode: 'Режим тестирования',
  trigger_object_btn_create_state: 'Добавить состояние',
  trigger_scene_hdr_standart_effects: 'Стандартные эффекты',
  trigger_text_hdr_name_lbl: 'Триггер',
  trigger_object_lbl_context_icon: 'Контекстная иконка',
  trigger_panel_object_lbl_gif_effects: 'GIF анимация',
  trigger_panel_select_plh: 'Выберите эффект',
  trigger_scene_hdr_effect: 'Стандартные эффекты',
  trigger_essence_hdr_effect: 'Эффекты',
  trigger_line_hdr_effect: 'Эффекты',
  trigger_scene_lbl_color: 'Цвет линии',
  trigger_popup_reset_triggers_hdr: 'Сброс изменений',
  trigger_popup_delete_triggers_hdr: 'Удаление триггера',
  trigger_notification_save_text: 'Вы уверены, что хотите сбросить изменения?',
  trigger_notification_save_btn_reset: 'Сбросить',
  trigger_notification_delete_text_items: 'Выбранное состояние будет удалено.\n Вы уверены, что хотите удалить триггер?',
  trigger_notification_reset_first_line: 'Триггеры были изменены.\n Закрытие окна приведет к потере данных.',
  trigger_notification_reset_second_line: 'Вы уверены, что хотите сбросить изменения?',
  trigger_scene_label_notification: 'Текст в сцене',
  trigger_text_plh_name: 'Введите название триггера'
}
