<template>
  <CbrDialog
    :value="value"
    :title="$t('event_card_popup_materials_hdr_manuals')"
    max-width="1048px"
    @input="$emit('input', false)"
  >
    <template slot="content">
      <div class="cbr-materials__container">
        <div v-if="state.isLoading" class="cbr-materials__loading"> {{ $t('event_creation_editing_lbl_observes_loading') }}</div>
        <div
          v-for="item in items"
          :key="item.name"
          class="cbr-materials__item"
        >
          <div class="cbr-materials__item-pdf">
            <CbrIcon class="cbr-materials___icon-pdf">$cbrFile</CbrIcon>
            <a class="cbr-materials__item-link" @click="openItem(item)">{{ item.name }}</a>
          </div>
          <div class="cbr-materials__item-download">
            <span class="cbr-materials__item-size">{{ item.size }}</span>
            <CbrButton
              icon="$cbrDownload"
              iconSize="22px"
              size="small"
              @click="downloadItem(item)"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="map-materials_botton">
        <CbrButton
            icon="$download_material"
            :text="$t('event_card_popup_materials_btn_download')"
            @click="downloadAll()"
            width="160px"
        />
      </div>
    </template>
  </CbrDialog>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import eventService from '@/services/event.service'
import missionService from '@/services/mission.service'
import jsFileDownload from 'js-file-download'
import { useStateLoading } from '@/composables/useStateLoading'
import { i18n } from '@/i18n-setup'

const { state, setStateLoading, setStateLoaded, setStateError } = useStateLoading()

const props = defineProps({
  id: Number,
  name: String,
  type: {
    type: String,
    required: true
  },
  materials: {
    type: Array,
    default: () => []
  },
  value: Boolean
})

let items = ref([])

onMounted(() => {
  if (!props.materials.length && props.type === 'event') {
    setStateLoading()
    setTimeout(() => {
      eventService.getEventMaterials(props.id).then(m => {
        items.value = eventService.parseMaterials(m || [])
        setStateLoaded()
      }).catch(() => {
        setStateError()
      })
    }, 5000)
  } else {
    items.value = props.materials
    setStateLoaded()
  }
})

const downloadAll = () => {
  let apiFunction = null
  switch (props.type) {
    case 'mission':
      apiFunction = missionService.getMissionMaterialsZipAsBlob
      break
    case 'event':
      apiFunction = eventService.getEventMaterialsZipAsBlob
      break
  }
  apiFunction(props.id).then(res => {
    jsFileDownload(res, i18n.t('event_card_manuals_zip'))
  })
}

const downloadItem = (item) => {
  let apiFunction = null
  switch (props.type) {
    case 'mission':
      apiFunction = missionService.getMissionMaterialsFileAsBlob
      break
    case 'event':
      apiFunction = eventService.getEventMaterialsFileAsBlob
      break
  }
  apiFunction(props.id, item.name).then(res => {
    jsFileDownload(res, item.name)
  })
}

const openItem = (item) => {
  let apiFunction = null
  switch (props.type) {
    case 'mission':
      apiFunction = missionService.getMissionMaterialsFile
      break
    case 'event':
      apiFunction = eventService.getEventMaterialsFile
      break
  }
  apiFunction(props.id, item.name)
}
</script>

<style lang="scss" scoped>

.cbr-materials{
  &__container {
    display: flex;
    flex-direction: column;
    width: 1000px;
    max-height: 270px;
    padding: 12px;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__item {
    background: rgba($base-color-light, 0.05);
    @include cut-corners(7px, true, 7px, false);
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: normal;
    padding: 0 10px;
  }

  &__item:last-child {
    margin-bottom: 0px;
  }

  &__item-pdf {
    display: flex;
    align-items: center;
  }

  &__item-link {
    color: $base-color-light;
    margin-left: 10px;
  }

  &__item-download {
    display: flex;
    align-items: center;
    color: $base-color-text;
  }

  &__item-size {
    margin-right: 12px;
  }
}
</style>
