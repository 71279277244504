import { getCurrentInstance, nextTick, onMounted, ref } from 'vue'

export function useElementTruncation (elementRefName) {
  const vueCurrentInstance = getCurrentInstance().proxy

  const element = ref(null)

  const isElementTruncated = ref(false)

  function calcIsElementTruncated () {
    nextTick(() => {
      if (!element.value) return

      isElementTruncated.value = element.value.scrollWidth > element.value.offsetWidth ||
        element.value.scrollHeight > element.value.offsetHeight
    })
  }

  onMounted(() => {
    element.value = vueCurrentInstance.$refs[elementRefName]

    calcIsElementTruncated()
  })

  return {
    isElementTruncated,
    calcIsElementTruncated
  }
}
