import { render, staticRenderFns } from "./CbrSlotSelector.vue?vue&type=template&id=31cbccc5&scoped=true"
import script from "./CbrSlotSelector.vue?vue&type=script&setup=true&lang=js"
export * from "./CbrSlotSelector.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CbrSlotSelector.vue?vue&type=style&index=0&id=31cbccc5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31cbccc5",
  null
  
)

export default component.exports