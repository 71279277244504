<template>
<div>
  <CbrCombobox
    class="cbr-slot-selector"
    :preselect="props.value"
    attached
    :items="getFilteredSlotsList"
    item-text="id"
    :placeholder="props.placeholder"
    :title="props.title"
    :id="id"
    clearable
    hide-details
    :item-disabled="isItemDisabled"
    :disabled="props.disabled"
    :error="props.error"
    :error-hint="props.errorHint"
    :required="props.required"
    :innerExpansionPanel="innerExpansionPanel"
    @input="onInput"
  >
    <template #item="{ item }">
      <div class="cbr-slot-selector__item">
        <Icon
          class="cbr-slot-selector__item-icon"
          :icon-name="getSlotIcon(item)"
          width="32px"
          height="32px"
        />
        <span class="cbr-slot-selector__item-id">{{ item.id }}</span>
      </div>
    </template>
    <template #selection="{ item }">
      <div class="cbr-slot-selector__slot-selection">
        <Icon
          class="cbr-slot-selector__item-icon"
          icon-name="event/event_slot"
          width="32px"
          height="32px"
        />
        <span class="cbr-slot-selector__item-id">{{ item.id }}</span>
      </div>
    </template>
  </CbrCombobox>
</div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  value: {
    type: Object,
    default: null,
  },
  slotsList: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  error: {
    type: Boolean,
    default: false
  },
  errorHint: {
    type: Object,
    default: null
  },
  forTeam: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  selectedSlots: {
    type: Array,
    default: () => []
  },
  innerExpansionPanel: Boolean,
  id: String
})
const emit = defineEmits(['input'])

function onInput (value) {
  emit('input', value)
}

const getFilteredSlotsList = computed(() => {
  return props.slotsList.filter(slot => !slot.locked)
})

function getSlotIcon (slot) {
  if (props.forTeam && isSlotSelectedByAnotherTeam(slot)) {
    return 'event/event_slot_selected'
  }
  if (slot.state !== 'READY') {
    return 'event/event_slot_busy'
  }
  return 'event/event_slot'
}
function isItemDisabled (item) {
  if (item.state !== 'READY') {
    return true
  }
  if (props.forTeam && isSlotSelectedByAnotherTeam(item)) {
    return true
  }
}
function isSlotSelectedByAnotherTeam (slot) {
  return props.selectedSlots.some(selectedSlot => selectedSlot && selectedSlot.id === slot.id)
}

</script>

<style scoped lang="scss">
.cbr-slot-selector {
  &__item {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &__item-id {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 21.33px;
    text-align: left;
    color: $ActionColor;
  }
  &__slot-selection {
    margin-left: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
</style>
