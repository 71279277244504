import store from '@/store'

export default class GifEffect {
  constructor (name, essence, canvas) {
    this.player = store.state.map.players.find(i => i.cvs.id === canvas.id)
    this.essence = essence
    this.name = name
    this.x = essence.x + essence
    this.y = essence.y
    this.essenceCenterX = essence.centerX
    this.essenceCenterY = essence.centerY
    this.img = new Image()
    this.img.onload = () => {
      this.width = this.img.width
      this.height = this.img.height
    }
    this.hideComponent = false
    this.img.style.position = 'absolute'
    this.img.className = 'gifEffect'
    this.img.onmousedown = () => false
    this.essenceOffsetX = 0
    this.essenceOffsetY = 0
    this.isEffectPlay = false
    this.effects = store.state.map.gifEffectsData
    this.MakeImgSrc()
    this.SetCanvas(canvas)
  }

  SetCanvas (canvas) {
    this.canvas = canvas
    if (this.canvas.classList.contains('modal')) {
      this.img.style.zIndex = 1000
    }
    this.playerOffsets = this.canvas.getBoundingClientRect()
  }

  MakeImgSrc () {
    for (const i in this.effects) {
      if (this.effects[i].name === this.name) {
        if (this.effects[i].componentName === this.essence.name) {
          this.img.src = this.effects[i].image
          this.img.style.display = 'none'
          this.essenceOffsetX = this.effects[i].offsetX
          this.essenceOffsetY = this.effects[i].offsetY
          this.hideComponent = this.effects[i].hideComponent
        }
      }
    }
  }

  Draw (x, y) {
    this.x = (x * this.player.zoom)
    this.y = (y * this.player.zoom)
    if (this.canvas.id === 'player') {
      this.img.style.left = this.x + (this.essenceOffsetX * this.player.zoom) - (this.essenceCenterX * this.player.zoom) + (this.player.translateX) + this.playerOffsets.left + 'px'
      this.img.style.top = this.y + (this.essenceOffsetY * this.player.zoom) - (this.essenceCenterY * this.player.zoom) + (this.player.translateY) + this.playerOffsets.top - this.player.topOffset + 'px'
    } else {
      this.img.style.left = 0 + this.player.cvs.width / 2 - 24 + 'px'
      this.img.style.top = 0 + this.player.cvs.height / 2 - 24 + 'px'
    }
    if (this.width) {
      // деление на 5, так как максимальный зум приложение х5, и загруженные гиф будут в максимальном размере (чтобы не было растрирования)
      this.img.style.width = (this.width / 5) * this.player.zoom + 'px'
      this.img.style.height = (this.height / 5) * this.player.zoom + 'px'
      this.img.style.display = 'block'
      this.img.setAttribute('data-gif', this.name)
      if (!this.isEffectPlay) {
        let container
        if (this.canvas.id === 'player') {
          container = document.getElementsByClassName('tools')[0]
        } else {
          container = document.getElementsByClassName('cbr-trigger-dialog__object-preview')[0]
          container.style.position = 'relative'
        }
        container.append(this.img)
      }
    }
  }

  ResetEffect () {
    this.isEffectPlay = false
    try {
      if (this.img.parentNode) this.img.parentNode.removeChild(this.img)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}
