<template>
  <nav class="cbr-tabs">
    <div
      v-for="tab in props.tabs"
      :key="tab.id"
      class="cbr-tabs__tab"
      :class="getTabClasses(tab)"
      @click="onTabClick(tab)"
    >
      {{ tab.name }}
    </div>
  </nav>
</template>

<script setup>
const props = defineProps({
  tabs: {
    type: Array,
    required: true,
    validator: function (val) {
      // check every tab has defined 'id' and 'name' property and they have string type
      return val.length > 1 && val.every(tab => typeof tab.id === 'string' && typeof tab.name === 'string')
    }
  },
  value: {
    type: String,
    required: true
  }
})
const emit = defineEmits(['input'])

const getTabClasses = (tab) => {
  const baseClass = 'cbr-tabs__tab'
  return {
    [`${baseClass}--active`]: props.value === tab.id
  }
}
const onTabClick = (tab) => {
  emit('input', tab.id)
}
</script>

<style scoped lang="scss">
.cbr-tabs {
  display: flex;
  width: fit-content;

  &__tab {
    height: 36px;
    width: fit-content;
    min-width: 120px;
    padding: 8px;
    border: 2px solid $ActionColor;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 100%;
    color: $ActionColor;
    cursor: pointer;

    &:first-child {
      @include cut-corners(7px, true, 0px, true, 2px, $ActionColor)
    }
    &:last-child{
      @include cut-corners(0px, true, 7px, true, 2px, $ActionColor)
    }

    &--active {
      background-color: $ActionColor;
      color: $StaticDark;
      font-family: $primary-font-family-medium;
    }
  }
}
</style>
