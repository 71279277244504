export const authEn = {
  change_exp_pwd: {
    lbl: {
      expired_1: {
        span_1: 'Password for ',
        span_2: 'is expired.',
      },
      expired_2: 'You must change password!',
      reqs_line1: {
        span_1: 'Password length not less than 10 ',
        span_2: 'letters'
      },
      reqs_line2: 'Password was not used recently',
      reqs_line3: {
        span_1: 'Must contain ',
        span_2: 'lower and upper case ',
        span_3: 'letters'
      },
      reqs_line4: {
        span_1: 'Must contain ',
        span_2: 'arabic numerals'
      },
      reqs_line5: {
        span_1: 'Must contain at least one special letter:',
        span_2: '! $ & ’ " ` | ( ) < > [ ] { } + - / , : ; = ? @ _ ~'
      },
      error_required: 'Required field',
      error_wrong_pwd: 'Wrong password!',
      error_weak_pwd: 'Unsecure password or password contains invalid characters!',
      error_retry_pwd: 'Passwords mismatch!',
      error_same_pwd: 'New password must be different from old',
    },
    hdr: {
      reqs: 'Password security requirements'
    },
    edit: {
      old_pwd_plh: 'Type old password',
      new_pwd_plh: 'Type new password',
      retry_pwd_plh: 'Repeat new password'
    },
    btn: {
      confirm: 'Change password',
      back: 'Back',
    }
  },

  user_authorization: {
    lbl: {
      rostelecom: 'Developed by Rostelecom'
    }
  }
}
