import { render, staticRenderFns } from "./CbrContentBlock.vue?vue&type=template&id=7b1e3683&scoped=true"
import script from "./CbrContentBlock.vue?vue&type=script&setup=true&lang=js"
export * from "./CbrContentBlock.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CbrContentBlock.vue?vue&type=style&index=0&id=7b1e3683&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b1e3683",
  null
  
)

export default component.exports