import store from '@/store'
import roles from '@/helpers/roles.helper'
import users from '@/helpers/users.helper'

const permissions = {
  allowedChangeEventState: () => {
    const event = store.getters['global/currentEvent']
    const user = store.getters['account/user']
    const currentUserId = store.getters['account/currentUserId']
    const isSystemAdmin = store.getters['account/isSystemAdmin']
    const isAdmin = store.getters['account/isAdmin']
    const isInternal = store.getters['account/isInternal']

    if (currentUserId === event?.teacher.id) return true
    if (isAdmin && isInternal) return true
    if (isSystemAdmin && user?.organization.id === (event?.teacher.org?.id || event?.teacher.organization?.id || event?.teacher.orgId)) {
      return true
    }
    return false
  },
  allowedJoinInEvent: (event) => {
    const user = store.getters['account/user']
    return (roles.adminRoles(user) && (user.organization.internal || ((event.teacher?.org || event.teacher?.organization)?.id || event.teacher?.orgId) === user.organization.id)) ||
        event.teams.flatMap(t => t.participants).find(u => u.id === user.id) ||
        event.observers?.find(u => u.id === user.id)
  },
  allowedShowProfile: (user, event, roleKey = 'role') => {
    const currentUser = store.getters['account/user']
    const currentRole = currentUser.role
    const userRole = user[roleKey]
    const currentOrgId = currentUser.organization.id
    const userOrgId = user.organization.id
    const isSameEvent = event.teams.some(team => team.participants.some(participant => participant.id === user.id)) || event.observers.some(observer => observer.id === user.id) || (event.teacher && event.teacher.id === user.id)

    const canShowProfile = (conditions) => {
      if (conditions) {
        users.showProfile(user)
      }
    }

    switch (currentRole) {
      case roles.ADMIN.key:
        canShowProfile(true)
        break

      case roles.TEACHER.key:
      case roles.INFRASTRUCTURE_ARCHITECT.key:
      case roles.RESEARCHER.key:
      case roles.SCREENWRITER_METHODOLOGIST.key:
        canShowProfile(
          currentUser.id === user.id ||
          (roles.isTeacherRole(userRole) && userOrgId === currentOrgId) ||
          (userRole === roles.USER.key && isSameEvent && userOrgId === currentOrgId) ||
          (userRole === roles.OBSERVER.key && isSameEvent && userOrgId === currentOrgId) ||
          (userRole === roles.ADMIN.key && userOrgId === currentOrgId)
        )
        break

      case roles.OBSERVER.key:
        canShowProfile(
          currentUser.id === user.id ||
          (roles.isTeacherRole(userRole) && isSameEvent) ||
          (userRole === roles.USER.key && isSameEvent) ||
          (userRole === roles.OBSERVER.key && isSameEvent)
        )
        break

      case roles.USER.key:
        canShowProfile(
          currentUser.id === user.id ||
          (roles.isTeacherRole(userRole) && isSameEvent) ||
          (userRole === roles.USER.key && isSameEvent)
        )
        break

      case roles.AUDITOR.key:
        canShowProfile(currentUser.id === user.id)
        break

      default:
        break
    }
  }
}

export default permissions
