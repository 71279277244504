<template>
  <div class="constructor-popup">
    <div class="constructor-popup__header">
      <span class="constructor-popup__header-txt">{{ $t('test_animation_hdr_test')}}</span>
      <div class="constructor-popup__header-switch">
        <CbrSwitch
          v-model="switchJson"
          :label="`${switchJson ? 'JSON' : $t('test_animation_btn_default')}`"
          class="constructor-popup__header-btn"
          no-icons
          medium
        />
      </div>
    </div>
    <div class="constructor-popup__wrapper">
      <div class="constructor-popup__scene" v-if="baseItems[0]">
        <div class="scene-items">
          <div class="scene-wrapper" :key="baseItem.baseId" v-for="baseItem in baseItems">
            <CbrButton
              :text="`Base ${baseItem.countNumber}`"
              :border="!baseItem.active ? true : false"
              @click="applyBase(baseItem)"
              class="scene-btn"
              size="small"
            />
            <CbrButton
              @click="deleteBase(baseItem)"
              icon="$cbrBasket"
              cbr-icon
              :mdi-icon-color="$h.colors.baseColors.$StaticLight"
              size="small"
              error
            />
          </div>
        </div>
      </div>
      <div class="constructor-popup__content">
        <CbrContentBlock
          maxHeight="560px"
          withBorder
        >
          <div class="constructor-content__options-wrapper" v-if="!switchJson">
            <CbrTextField
              v-model="base.round"
              title="Round"
            />
            <CbrTextField
              v-model="base.vector"
              title="Vector"
            />
            <CbrTextField
              v-model="base.phase"
              title="Phase"
            />
          </div>
          <div class="constructor-content" v-else>
            <div class="constructor-content__textarea-wrapper">
              <v-textarea
                :value="baseJson"
                @change="setBaseValues"
                dense="dense"
                height="510px"
                no-resize="no-resize"
                maxlength="2048"
                class="constructor-content__textarea"
              ></v-textarea>
            </div>
          </div>
        </CbrContentBlock>
      </div>
    </div>
    <div class="constructor-popup__footer">
      <div class="constructor-popup__btn-wrapper">
        <CbrButton
          :text="$t('test_animation_btn_add_base_item')"
          @click="addBaseItem"
          icon="$cbrPlus"
          cbr-icon
          width="450px"
          border
        />
      </div>
      <div class="constructor-popup__btn-footer">
        <CbrButton
          @click="update"
          :text="$t('test_animation_btn_update_scene')"
          icon="$cbrRefresh"
          cbr-icon
          width="220px"
          border
        />
        <CbrButton
          @click="resetBase"
          :text="$t('test_animation_btn_reset_scene')"
          width="220px"
          error
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from '@/store'
import { tools } from '@/plugins/map/constructor/tools'
import { constructor } from '@/plugins/map/constructor/player'
import _ from 'lodash'

const switchJson = ref(false)
const store = useStore()

const base = computed(() => store.getters.base)
const baseItems = computed(() => store.getters.baseItems)
const baseObj = computed(() => store.getters.switchBaseObj)
const baseJson = computed(() => JSON.stringify(
  store.getters.base,
  (key, value) => ['baseId', 'stage', 'countNumber', 'active'].includes(key) ? undefined : value,
  2
))

const update = () => {
  inactiveBaseItems()
  constructor.Update()
}

const addBaseItem = () => {
  inactiveBaseItems()
  const baseCopy = { ...base.value }
  baseCopy.baseId = Date.now()
  baseCopy.countNumber = (_.last(baseItems.value)?.countNumber || 0) + 1
  baseCopy.active = true
  tools.AddBaseItem(baseCopy)
  tools.ApplyBase(base.value)
  constructor.Update()
}

const resetBase = () => {
  constructor.resetBase()
  tools.ApplyBase({ ...tools.initialBase })
  inactiveBaseItems()
}

const applyBase = (baseToApply) => {
  inactiveBaseItems()
  baseToApply.active = true
  tools.ApplyBase({ ...baseToApply })
  constructor.Update()
}

const inactiveBaseItems = () => {
  baseItems.value.forEach(item => (item.active = false))
}

const deleteBase = (baseToDelete) => {
  store.commit('removeBaseItem', baseToDelete.baseId)
  if (!baseItems.value.length) resetBase()
}

const setBaseValues = (json) => {
  try {
    const newBase = { ...base.value, ...JSON.parse(json) }
    store.commit('base', newBase)
  } catch (e) {
    console.error('It is not valid JSON')
  }
}

watch(baseObj, (val) => {
  if (val) {
    tools.initialBase = { ...base.value }
    constructor.essence.forEach(essence => {
      essence.defaultState = {
        state: essence.state,
        effect: essence.effect,
        icons: essence.icons,
        progress: essence.progress
      }
    })
    constructor.lines.forEach(line => {
      line.defaultState = {
        color: line.color,
        effect: line.effect,
        animationDirectionFromLeft: line.animationDirectionFromLeft,
        memoColor: line.memoColor,
        lineWidth: line.lineWidth
      }
    })
    constructor.planes.forEach(plane => {
      plane.defaultState = {
        bgColor: plane.bgColor,
        lineColor: plane.lineColor
      }
    })
    constructor.textFields.forEach(text => {
      text.defaultState = {
        textSize: text.textSize,
        message: text.message,
        color: text.color
      }
    })
    constructor.scene.defaultState = {
      effect: constructor.scene.effect,
      progress: constructor.scene.progress,
      Notification: constructor.scene.Notification,
      NotificationColor: constructor.scene.NotificationColor
    }
  } else {
    resetBase()
  }
})
</script>

<style lang="scss" scoped>
$constructor_background: #1d334e;
$constructor_textarea: rgba(166, 166, 166, 1);

.constructor-popup {
  display: flex;
  flex-direction: column;
  width: 485px;
  margin: 16px 0px 38px 8px;
  position: absolute;
  height: calc(100vh - 84px);
  z-index: 99;
  background-color: $constructor_background;
  padding: 16px;
  overflow-x: auto;
  @include scrollbar();
  clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), 0 100%, 0 10px);

  &__header {
    display: flex;
    flex-direction: column;
    height: 82px;
    width: 100%;
    margin-top: 8px;
    border-bottom: 1px solid rgba($base-color-light, 0.15);
  }

  &__header-txt {
    color: $base-color-text;
    font-size: 16px;
  }

  &__header-switch {
    width: 220px;
  }

  &__header-btn {
    margin: 8px 0px 16px 0px;
  }

  &__wrapper {
    display: flex;
    margin-top: 16px;
  }

  &__scene {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
  }

  &__content {
    width: 100%;
  }

  &__options-wrapper {
    display: flex;
    flex-direction: column;
    height: 500px;
  }

  .constructor-content {
    ::v-deep .v-input__slot {
      display: flex;
      align-items: start;
    }

    &__textarea {
      margin-top: 16px;
      ::v-deep .v-input__slot {
        display: flex;
        align-items: start;
      }

      ::v-deep .v-input__slot::before {
        display: none;
      }

      ::v-deep .v-input__slot::after {
        display: none;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    bottom: 0;
    height: 140px;
    margin-top: 16px;
    ::v-deep path {
      fill: $base-color !important;
    }
  }

  &__btn-footer {
    display: flex;
    justify-content: space-between;
  }

  &__btn-wrapper {
    height: 68px;
    border-bottom: 1px solid rgba($base-color-light, 0.15);
  }
}

::v-deep .v-textarea textarea {
  height: 500px !important;
  font-size: 16px;
  color: $constructor_textarea;
  line-height: 20px;
}

.scene-wrapper {
  display: flex;
  width: 100%;
  max-width: 140px;
  justify-content: space-between;
}

.scene-wrapper:not(:first-child) {
  margin-top: 16px;
}

.scene-btn {
  margin-right: 10px;
}

::v-deep .cbr-textarea.medium .cbr-textarea-container.valid-input:not(.required):not(.error--text):not(.disabled):not(:focus-within) .v-input__slot {
  border: none;
  background-color: $constructor_background;
  height: 500px;
}
</style>
