import { i18n } from '@/i18n-setup'

const baseURL = '/api'
const fetchClient = (endPoint, headers = {}, method = 'GET', body) => {
  return fetch(baseURL + endPoint, {
    headers: Object.assign({
      'Accept-Language': i18n.locale
    }, headers),
    method,
    body
  }).then(res => {
    if (!res.ok) return Promise.reject(res)
    if (res.status !== 200) return Promise.resolve(res)
    if (headers.responseType === 'blob') return Promise.resolve(res)
    if (headers.responseType === 'text/markdown') return Promise.resolve(res)
    return res.json()
  })
}

export default fetchClient
