export default {
  baseColors: {
    $ActionColor: '#00FFF0',
    $ErrorColor: '#FD3749',
    $AlarmTextColor: '#F88',
    $WarningColor: '#FFCA00',
    $StaticDark: '#003956',
    $PopupDarkBack: '#0F2B3A',
    $StaticLight: '#FFFFFF',
    $baseBlack: '#000000'
  },

  secondaryColors: {
    $borderDisabled: '#006b7c'
  },

  hex2rgb (hex) {
    const bigint = parseInt(hex.split('#')[1], 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    return `${r}, ${g}, ${b}`
  },

  rgba (color, alpha) {
    return `rgba(${this.hex2rgb(this.baseColors[color])}, ${alpha.toString()})`
  }
}

export const tagTechniqueColors = ['#00BFE5', '#00CC9C', '#9973E5', '#E54575', '#E58F39']
