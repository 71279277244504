export default {
  quest_expand: {
    iconName: 'quest/quest_expand',
  },
  quest_collapse: {
    iconName: 'quest/quest_collapse',
  },
  quest_finish: {
    iconName: 'quest/quest_finish',
  },
  quest_back: {
    iconName: 'quest/quest_back',
  },
  quest_submit: {
    iconName: 'quest/quest_submit',
  },
  quest_rollback: {
    iconName: 'quest/quest_rollback'
  },
  quest_edited: {
    iconName: 'quest/quest_edited'
  },
  quest_needs_evaluation: {
    iconName: 'quest/quest_needs_evaluation'
  },
  quest_scored_field: {
    iconName: 'quest/quest_scored_field'
  },
  quest_changed_field: {
    iconName: 'quest/quest_changed_field'
  },
  quest_validation_error: {
    iconName: 'quest/quest_validation_error'
  },
  quest_validation_warning: {
    iconName: 'quest/quest_validation_warning'
  },
  quest_validation_info: {
    iconName: 'quest/quest_validation_info'
  },
}
