const quest = {
  namespaced: true,
  state: {
    currentQuestPath: null,
    currentTaskInfo: null,
  },
  getters: {
    currentQuestPath: state => state.currentQuestPath,
    currentTaskInfo: state => state.currentTaskInfo,
  },
  mutations: {
    setCurrentQuestPath (state, payload) {
      state.currentQuestPath = payload
    },
    setCurrentTaskInfo (state, payload) {
      state.currentTaskInfo = payload
    },
  }
}

export default quest
