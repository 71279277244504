import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import roles from '@/helpers/roles.helper'
import users from '@/helpers/users.helper'
import RunEvent from '../views/event/RunEvent'
import Home from '../views/Home'
import Login from '@/views/Login'
import { i18n } from '@/i18n-setup'
import { CTF, DEFENCE, QUEST, RESISTANCE } from '@/services/event.service'
import ForbiddenBackEvents from '@/components/error/ForbiddenBackEvents'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      breadCrumb: [
        {
          text: 'user_card_hdr_user_profile',
          role: [roles.USER.key],
          disabled: true
        }
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      noAuth: true
    },
    component: Login
  },
  {
    path: '/password-expired',
    name: 'PasswordExpired',
    meta: {
      noAuth: true,
      hideNavigation: true,
    },
    component: () => import('@/views/ExpiredPasswordChange.vue')
  },
  {
    path: '/changePassword/:token',
    name: 'ChangePassword',
    component: () => import('@/views/ChangePassword'),
    meta: {
      noAuth: true,
      hideNavigation: true
    },
    props: true
  },
  {
    path: '/missions',
    name: 'Missions',
    component: () => import('@/views/MissionsTab'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      breadCrumb: (route) => {
        return [
          {
            text: i18n.t('missions_list_crumbs_crumb')
          },
          {
            text: route.query.tab === 'quests-list'
              ? i18n.t('missions_list_crumbs_crumb_task')
              : route.query.tab === 'scripts'
                ? i18n.t('missions_list_crumbs_crumb_script')
                : i18n.t('missions_list_crumbs_crumb_mission'),
            disabled: true
          }
        ]
      },
      internalOrg: true
    }
  },
  // Events
  {
    path: '/createEvent/:type',
    name: 'CreateEvent',
    component: () => import('@/views/event/CreateEditEvent.vue'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      breadCrumb: (route, store) => {
        const eventTypesTitlesMap = {
          [CTF]: i18n.t('common_event_type_ctf'),
          [RESISTANCE]: i18n.t('common_event_type_rvb'),
          [DEFENCE]: i18n.t('common_event_type_defence'),
          [QUEST]: i18n.t('common_event_type_quest')
        }
        return [
          {
            text: [
              {
                text: `${i18n.t('event_creation_editing_crumbs_create_event')} -`,
                style: {
                  color: '#ffffff80'
                }
              },
              {
                text: eventTypesTitlesMap[route.params.type],
                style: {
                  color: '#fff'
                }
              }
            ],
            disabled: true
          },
        ]
      },
    },
    props: true
  },
  {
    path: '/event/:type/:eventId/edit',
    name: 'EditEvent',
    component: () => import('@/views/event/CreateEditEvent.vue'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      breadCrumb: (route, store) => {
        const currEvent = store.getters['global/currentEvent']
        return [
          {
            text: i18n.t('event_creation_editing_crumbs_edit_event'),
            customText: true,
            style: {
              color: '#ffffff80',
              overflow: 'visible'
            }
          },
          {
            text: currEvent?.name,
            customText: true,
            style: {
              color: '#fff'
            },
            to: `/event/${route.params.type}/${currEvent?.id}`
          }
        ]
      },
    },
    props: true
  },
  {
    path: '/event/:type/:eventId',
    name: 'EventCard',
    component: () => import('@/views/event/EventCard.vue'),
    meta: {
      breadCrumb: (route, store) => {
        const currEvent = store.getters['global/currentEvent']
        return [
          {
            text: `${route.params.type !== QUEST ? i18n.t('user_card_hdr_column_event') : ''}
              ${currEvent?.name || ''}`,
            ellipsis: true,
            customText: true,
          }
        ]
      },
      ignoreGlobalEventLoading: true
    }
  },
  {
    path: '/teacher-report/:eventId/:userId',
    name: 'EventTeacherReport',
    component: () => import('@/views/event/EventTeacherReport'),
    props: true,
    meta: {
      breadCrumb: (route, store) => {
        const currEvent = store.getters['global/currentEvent']
        const reportUser = !currEvent ? ''
          : `${i18n.t('event_card_link_report')} ${users.getFullName(currEvent.teams[0].participants.find(f => f.id === +route.params.userId))}`
        return [
          {
            ellipsis: true,
            text: `${currEvent?.name ?? ''} - ${currEvent?.organization.name ?? ''}`,
            customText: true,
            to: `/event/defence/${currEvent?.id}`
          },
          {
            text: reportUser || 'event_card_link_participant_report',
            customText: !!reportUser,
            disabled: true
          }
        ]
      },
    }
  },
  {
    path: '/event/:type/:eventId/report',
    name: 'EventPlayerReport',
    component: () => import('@/views/event/EventPlayerReport'),
    props: true,
    meta: {
      breadCrumb: (route, store) => {
        const currEvent = store.getters['global/currentEvent']
        const userFullName = store.getters['account/userFullName']
        return [
          {
            ellipsis: true,
            text: `${currEvent?.name ?? ''} - ${currEvent?.organization.name ?? ''}`,
            customText: true,
            to: `/event/defence/${currEvent?.id}`
          },
          {
            ellipsis: true,
            text: `${i18n.t('event_card_link_report')} ${userFullName}`,
            customText: true,
            disabled: true
          }
        ]
      },
      role: [roles.USER.key],
      ignoreGlobalEventLoading: true
    }
  },
  {
    path: '/runEvent/:type/:eventId',
    name: 'runEvent',
    component: RunEvent,
    meta: {
      role: [roles.ADMIN.key, roles.USER.key, ...roles.teacherRoles(), roles.OBSERVER.key]
    }
  },
  {
    path: '/mission/:id',
    name: 'missionCard',
    component: () => import('@/views/Mission'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      internalOrg: true,
      breadCrumb: (route, store) => {
        const currMission = store.getters['global/currentMission']
        return [
          {
            text: 'event_creation_editing_crumbs_all_missions',
            to: '/missions'
          },
          {
            text: `${currMission?.name ?? ''}`,
            ellipsis: true,
            customText: true,
          }
        ]
      },
    },
    props: true
  },
  {
    path: '/mission/:id/:eventId',
    name: 'missionCardByEvent',
    component: () => import('@/views/Mission'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      internalOrg: true,
      breadCrumb: (route, store) => {
        const currMission = store.getters['global/currentMission']
        return [
          {
            text: 'event_creation_editing_crumbs_all_missions',
            to: '/missions'
          },
          {
            text: `${currMission?.name ?? ''}`,
            ellipsis: true,
            customText: true,
          }
        ]
      },
      ignoreGlobalEventLoading: true
    },
    props: true
  },
  {
    path: '/editMission/:id',
    name: 'missionEditForm',
    component: () => import('@/views/mission/MissionEditForm.vue'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      breadCrumb: (route, store) => {
        return [
          {
            text: 'event_creation_editing_crumbs_all_missions',
            to: '/missions'
          },
          {
            text: store.getters['global/editingMission']?.name,
            to: `/mission/${route.params.id}`,
            disabled: store.getters['global/hasUnpublishedData']
          },
          {
            text: i18n.t('mission_cnstrctr_crumbs_constructor'),
            disabled: true
          }
        ]
      },
      internalOrg: true
    },
    props: true
  },

  {
    path: '/lk/user',
    name: 'LkUser',
    component: () => import('@/views/lk/LkUser'),
    props: true,
    meta: {
      role: [roles.USER.key],
      breadCrumb: (route, store) => {
        return [
          {
            text: i18n.t(`personal_account.lbl.user_${store.getters['lk/tab']}`),
            customText: true,
            disabled: true
          }
        ]
      }
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/Admin'),
    props: true,
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      breadCrumb: (route) => {
        let tab = route.query.tab
        switch (tab) {
          case 'users': tab = 'user_management'
            break
          case 'organizations': tab = 'organization_management'
            break
          case 'infrastructure': tab = 'infra'
            break
          case 'scoreboard': tab = 'scoreboard'
            break
          default: tab = 'user_management'
        }
        return [
          {
            text: 'navigation_panel_popup_menu_btn_admin_panel',
            disabled: true
          },
          {
            text: `${i18n.t(`admin_panel_hint_${tab}`)}`,
            customText: true,
            disabled: true
          }
        ]
      }
    }
  },
  {
    path: '/audit',
    name: 'audit',
    component: () => import('@/views/Audit'),
    props: true,
    meta: {
      role: [roles.AUDITOR.key],
      internalOrg: true
    }
  },
  {
    path: '/connect/:slot/:vm',
    name: 'connectToVm',
    component: () => import('@/views/ConnectToVm.vue'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
    }
  },
  {
    path: '/event-scoreboard/:type/:eventId',
    name: 'EventScoreboard',
    component: () => import('@/views/EventScoreboard'),
    meta: {
      noAuth: true,
      breadCrumb: (route, store) => {
        const currentEvent = store.getters['global/currentEvent']
        return [
          {
            text: `${currentEvent?.name ?? ''}`,
            customText: true,
            disabled: true
          }
        ]
      },
    }
  },
  {
    path: '/scoreboard',
    name: 'scoreboard',
    component: () => import('@/views/Scoreboard.vue'),
    props: true,
    meta: {
      role: [roles.ADMIN.key, roles.OBSERVER.key, ...roles.teacherRoles(), roles.USER.key],
      breadCrumb: [
        {
          text: 'toolbar_results_table',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/command-scoreboard/:token',
    name: 'CommandScoreboard',
    component: () => import('@/views/CommandScoreboard.vue'),
    meta: {
      noAuth: true,
      breadCrumb: (route, store) => {
        return [
          {
            text: 'navigation_panel_popup_menu_btn_admin_panel',
            to: '/admin'
          },
          {
            text: store.getters['global/commandScoreboard']?.title ?? '',
            customText: true,
            ellipsis: true
          }
        ]
      }
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      internalOrg: true
    }
  },
  {
    path: '/integrityControl',
    name: 'integrityControl',
    component: () => import('@/views/IntegrityControl'),
    meta: {
      role: [roles.ADMIN.key],
      internalOrg: true,
      breadCrumb: [
        {
          text: 'integrity_control_lbl_integrity_control',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/appError',
    name: 'error',
    component: () => import('@/views/Error'),
    props: true,
    meta: {
      noAuth: true,
      breadCrumb: [
        {
          text: 'events_list_lbl_error',
          disabled: true
        }
      ]
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/Logout'),
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import('@/components/map/Tools.vue'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()]
    }
  },
  {
    path: '/control',
    name: 'Control',
    component: () => import('@/views/Control'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()]
    }
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('@/views/Tasks'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      internalOrg: true,
      breadCrumb: (route) => {
        return [
          {
            text: 'task_card.lbl.bread_crumbs_root',
            disabled: true
          },
        ]
      },
    }
  },
  {
    path: '/task/:taskId',
    name: 'Task',
    component: () => import('@/views/Task'),
    meta: {
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      internalOrg: true,
      breadCrumb: (route) => {
        return [
          {
            text: 'task_card.lbl.bread_crumbs_root',
            to: '/tasks'
          },
          {
            text: `${i18n.t('task_card.lbl.number_task')} ${route.params.taskId}`,
            customText: true,
            disabled: true
          }
        ]
      },
    }
  },
  {
    path: '/event/:eventId/quest/:questId/user/:userId/report',
    name: 'QuestReport',
    component: () => import('@/views/QuestReport.vue'),
    meta: {
      breadCrumb: (route, store) => {
        const currEvent = store.getters['global/currentEvent']
        return [
          {
            ellipsis: true,
            text: `${currEvent?.name ?? ''}`,
            customText: true,
            to: `/event/quest/${currEvent?.id}` // TODO должен быть переход на карточку мероприятия на таб "правила" (привязка табов к роутеру в процессе у Степы)
          },
          {
            text: 'solarquest_report_hdr_breadcrumbs',
            to: `/event/quest/${currEvent?.id}?tab=quests` // TODO должен быть переход на карточку мероприятия на таб "задания" (привязка табов к роутеру в процессе у Степы)
          },
          {
            text: store.getters['global/currentQuest']?.title ?? '', // TODO видимо нужно будет подтягивать название из стора,
            customText: true,
          },
        ]
      },
      role: [roles.USER.key],
      ignoreGlobalEventLoading: true
    }
  },
  {
    path: '/quest-report-evaluation/:eventId/:questId/:userId',
    name: 'QuestReportEvaluation',
    component: () => import('@/views/QuestReportEvaluation.vue'),
    meta: {
      breadCrumb: (route, store) => {
        const currEvent = store.getters['global/currentEvent']
        return [
          {
            ellipsis: true,
            text: `${currEvent?.name ?? ''}`,
            customText: true,
            to: `/event/quest/${currEvent?.id}`
          },
          {
            text: users.getNameWithMiddleNameInitials(
              currEvent?.teams
                .reduce((acc, cur) => acc.concat(cur.participants), [])
                .find(({ id }) => id === +route.params.userId) ?? {}),
            customText: true
          },
          {
            text: currEvent?.quests?.find(({ id }) => id === route.params.questId).title,
            customText: true
          },
        ]
      },
      role: [roles.ADMIN.key, ...roles.teacherRoles()],
      ignoreGlobalEventLoading: true
    }
  },
  {
    path: '/visualization/:type/:eventId',
    name: 'QuestVisualization',
    component: () => import('@/views/QuestVisualization.vue'),
  },
  {
    path: '/missions?tab=quests-list',
    name: 'QuestList',
    component: () => import('@/views/mission/quest-list/QuestList.vue'),
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.historyData = []
router.prev = function () {
  return router.push(router.historyData[router.historyData.length - 1])
}

router.beforeEach((to, from, next) => {
  router.historyData.push(from)

  const logoutStatus = localStorage.getItem('logoutStatus')
  if (!store.getters['account/isInit'] && logoutStatus !== 'failed') {
    store.dispatch('account/retrieveUser')
      .then(() => routerAuth(to, from, next))
      .catch(() => routerAuth(to, from, next))
  } else {
    routerAuth(to, from, next)
  }
})

function routerAuth (to, from, next) {
  const userAuthenticated = store.getters['account/isLoggedIn']
  const userRole = store.getters['account/userRole']
  const isAuditor = store.getters['account/isAuditor']
  const isInternalOrg = store.getters['account/userOrganization']?.internal

  if ((!to.meta.noAuth || to.meta.role) && !userAuthenticated) {
    next('/login')
    return
  }

  if ((to.meta.role && to.meta.role.indexOf(userRole) === -1) ||
    (to.meta.internalOrg !== undefined && to.meta.internalOrg !== isInternalOrg)
  ) {
    next({
      name: 'error',
      params: {
        errorText: i18n.t('user_panel_popup_no_access_rights_txt_page_view'),
        action: isAuditor ? null : ForbiddenBackEvents
      }
    })
    return
  }

  if (userAuthenticated && to.name === 'Login') {
    next('/')
    return
  }

  next()
}

export default router
