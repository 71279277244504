export default {
  reset: {
    iconName: 'mission/reset',
  },
  document: {
    iconName: 'mission/document',
  },
  document_orange: {
    iconName: 'mission/document_orange',
  },
  mission_error: {
    iconName: 'mission/error'
  },
  mission_warning: {
    iconName: 'mission/warning'
  },
  mission_warning_new: {
    iconName: 'mission/warning_new'
  },
  mission_constructor_checkmark_check: {
    iconName: 'mission/checkmark_check'
  },
  mission_constructor_checkmark_uncheck: {
    iconName: 'mission/checkmark_uncheck'
  },
  mission_constructor_export: {
    iconName: 'mission/export'
  },
  mission_constructor_import: {
    iconName: 'mission/import'
  },
  mission_constructor_add: {
    iconName: 'mission/add'
  },
  mission_constructor_add_plus: {
    iconName: 'mission/add_plus'
  },
  mission_constructor_edit_pencil: {
    iconName: 'mission/edit_pencil'
  },
  mission_constructor_edit: {
    iconName: 'mission/edit'
  },
  mission_constructor_edit_disabled: {
    iconName: 'mission/edit_disabled'
  },
  mission_constructor_copy_copy: {
    iconName: 'mission/copy_copy'
  },
  mission_constructor_copy: {
    iconName: 'mission/copy'
  },
  mission_constructor_copy_disabled: {
    iconName: 'mission/copy_disabled'
  },
  mission_constructor_delete_trash: {
    iconName: 'mission/delete_trash'
  },
  mission_constructor_play: {
    iconName: 'mission/play'
  },
  mission_menu: {
    iconName: 'mission/mission_menu'
  },
  mission_quests: {
    iconName: 'mission/mission_quests'
  }
}
